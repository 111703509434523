import {createMaxStringRule} from '@/utils/validation-rules';
import {SpecPackageCertificationMarkLang as lang, SpecPackageCertificationMarkAuthorityLang as langA} from "@/lang/enum/spec-package-certification-mark-lang";
import {getTextByLang as t} from "@/bootstraps/locale";

import ObjectUtils from "@/utils/object-utils";

export default class SpecPackageCertificationMarkEntity {
  public id!:number;
  public name:string|null = null;
  public authority:string|null = null;

  constructor(init:Partial<SpecPackageCertificationMarkEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);
  }
}

export const ValidatorRules = {
  name: [createMaxStringRule()],
  authority: [createMaxStringRule()]
};

export const CertificationMarkList = [
  { name: t(lang.スチール缶材質表示マーク), search: t(lang.スチール缶材質表示マーク) + "すちーるかんざいしつひょうじまーく", authority: t(langA.スチール缶リサイクル協会) },
  { name: t(lang.アルミ缶材質表示マーク), search: t(lang.アルミ缶材質表示マーク) + "あるみかんざいしつひょうじまーく", authority: t(langA.アルミ缶リサイクル協会) },
  { name: t(lang.PETボトル表示マーク), search: t(lang.PETボトル表示マーク) + "ぺっとぼとるひょうじまーく,ぴーいーてぃーぼとるひょうじまーく", authority: t(langA.PETボトルリサイクル推進協議会) },
  { name: t(lang.紙製容器包装マーク), search: t(lang.紙製容器包装マーク) + "かみせいようきほうそうまーく", authority: t(langA.紙製容器包装リサイクル推進協議会) },
  { name: t(lang.プラスチック製容器包装マーク), search: t(lang.プラスチック製容器包装マーク) + "ぷらすちっくせいようきほうそうまーく", authority: t(langA.プラスチック容器包装リサイクル推進協議会) },
  { name: t(lang.飲料用紙製容器マーク), search: t(lang.飲料用紙製容器マーク) + "いんりょうようかみせいようきまーく", authority: t(langA.飲料用紙容器リサイクル協議会) },
  { name: t(lang.段ボールの国際的シンボルマーク), search: t(lang.段ボールの国際的シンボルマーク) + "だんぼーるのこくさいてきしんぼるまーく", authority: t(langA.段ボールリサイクル協議会) },
  { name: t(lang.エコマーク), search: t(lang.エコマーク) + "", authority: t(langA.日本環境協会) },
  { name: t(lang.統一美化マーク), search: t(lang.統一美化マーク) + "とういつびかまーく", authority: t(langA.食品容器環境美化協会) },
  { name: t(lang.JASマーク), search: t(lang.JASマーク) + "じゃすまーく,じぇーえーえすまーく", authority: t(langA.日本農林規格) },
  { name: t(lang.有機JASマーク), search: t(lang.有機JASマーク) + "ゆうきじゃすまーく,ゆうきじぇーえーえすまーく", authority: t(langA.日本農林規格) },
  { name: t(lang.特定JASマーク), search: t(lang.特定JASマーク) + "とくていじゃすまーく,とくていじぇーえーえすまーく", authority: t(langA.日本農林規格) },
  { name: t(lang.飲用乳の公正マーク), search: t(lang.飲用乳の公正マーク) + "いんようにゅうのこうせいまーく", authority: t(langA.全国飲用牛乳公正取引協議会) },
  { name: t(lang.再生紙使用マーク), search: t(lang.再生紙使用マーク) + "さいせいししようまーくあーるまーく", authority: t(langA.ごみ減量化推進国民会議) },
  { name: t(lang.グリーンマーク), search: t(lang.グリーンマーク) + "", authority: t(langA.古紙再生促進センター) },
  { name: t(lang.プラスチック材質識別マーク1), search: t(lang.プラスチック材質識別マーク1) + "ぷらすちっくざいしきべつまーくえすぴーあいこーど", authority: t(langA.米国プラスチック産業協会) },
  { name: t(lang.プラスチック材質識別マーク2), search: t(lang.プラスチック材質識別マーク2) + "ぷらすちっくざいしきべつまーくえすぴーあいこーど", authority: t(langA.米国プラスチック産業協会) },
  { name: t(lang.プラスチック材質識別マーク3), search: t(lang.プラスチック材質識別マーク3) + "ぷらすちっくざいしきべつまーくえすぴーあいこーど", authority: t(langA.米国プラスチック産業協会) },
  { name: t(lang.プラスチック材質識別マーク4), search: t(lang.プラスチック材質識別マーク4) + "ぷらすちっくざいしきべつまーくえすぴーあいこーど", authority: t(langA.米国プラスチック産業協会) },
  { name: t(lang.プラスチック材質識別マーク5), search: t(lang.プラスチック材質識別マーク5) + "ぷらすちっくざいしきべつまーくえすぴーあいこーど", authority: t(langA.米国プラスチック産業協会) },
  { name: t(lang.プラスチック材質識別マーク6), search: t(lang.プラスチック材質識別マーク6) + "ぷらすちっくざいしきべつまーくえすぴーあいこーど", authority: t(langA.米国プラスチック産業協会) },
  { name: t(lang.プラスチック材質識別マーク7), search: t(lang.プラスチック材質識別マーク7) + "ぷらすちっくざいしきべつまーくえすぴーあいこーど", authority: t(langA.米国プラスチック産業協会) },
  { name: t(lang.JISマーク), search: t(lang.JISマーク) + "じすまーく,じぇーあいえすまーく", authority: t(langA.日本工業標準調査会) },
  { name: t(lang.特別用途食品マーク), search: t(lang.特別用途食品マーク) + "とくべつようとしょくひんまーく", authority: t(langA.消費者庁) },
  { name: t(lang.特定保健用食品マーク), search: t(lang.特定保健用食品マーク) + "とくていほけんようしょくひんまーく", authority: t(langA.消費者庁) },
  { name: t(lang.JHFAマーク), search: t(lang.JHFAマーク) + "じぇーえいちえふえーまーく", authority: t(langA.日本健康栄養食品協会) },
  { name: t(lang.環境に配慮した商品), search: t(lang.環境に配慮した商品) + "かんきょうにはいりょしたしょうひん", authority: t(langA.日本生活協同組合連合会) },
  { name: t(lang.PETボトルリサイクル推奨マーク), search: t(lang.PETボトルリサイクル推奨マーク) + "ぺっとぼとるりさいくるすいしょうまーく,ぴーいーてぃーぼとるさいくるすいしょうまーく", authority: t(langA.PETボトルリサイクル推進協議会) },
  { name: t(lang.米の認証マーク), search: t(lang.米の認証マーク) + "こめのにんしょうまーく", authority: t(langA.日本穀物検定協会) },
  { name: t(lang.精米表示検定済みマーク), search: t(lang.精米表示検定済みマーク) + "せいまいひょうじけんていずみまーく", authority: t(langA.日本穀物検定協会) },
  { name: t(lang.無洗米認証マーク), search: t(lang.無洗米認証マーク) + "むせんまいにんしょうまーく", authority: t(langA.全国無洗米協会) },
  { name: t(lang.HACCP), search: t(lang.HACCP) + "はさっぷ,えいちえーしーしーぴー", authority: t(langA.厚生労働大臣承認) },
  { name: t(lang.日本冷凍食品協会確認工場製品認定証マーク), search: t(lang.日本冷凍食品協会確認工場製品認定証マーク) + "にほんれいとうしょくひんきょうかいかくにんこうじょうせいひんにんていしょうまーく", authority: t(langA.社団法人日本冷凍食品協会) },
  { name: t(lang.ふるさと認証食品), search: t(lang.ふるさと認証食品) + "ふるさとにんしょうしょくひんいーまーく", authority: t(langA.各都道府県) },
  { name: t(lang.生めんの公正マーク), search: t(lang.生めんの公正マーク) + "なまめんのこうせいまーく", authority: t(langA.全国生めん類公正取引協議会) },
  { name: t(lang.辛子めんたいこ食品の公正マーク), search: t(lang.辛子めんたいこ食品の公正マーク) + "からしめんたいこしょくひんのこうせいまーく", authority: t(langA.全国辛子めんたいこ食品公正取引協議会) },
  { name: t(lang.ハムソーセージ類の公正マーク), search: t(lang.ハムソーセージ類の公正マーク) + "はむそーせーじるいのこうせいまーく", authority: t(langA.ハムソーセージ類公正取引協議会) },
  { name: t(lang.SQマーク), search: t(lang.SQマーク) + "えすきゅーまーく", authority: t(langA.社団法人菓子総合技術センター) },
  { name: t(lang.塩の安全衛生基準認定マーク), search: t(lang.塩の安全衛生基準認定マーク) + "しおのあんぜんえいせいきじゅんにんていまーく", authority: t(langA.社団法人日本塩工会) },
  { name: t(lang.はちみつの公正マーク), search: t(lang.はちみつの公正マーク) + "はちみつのこうせいまーく", authority: t(langA.全国はちみつ公正取引協議会) },
  { name: t(lang.みその公正マーク), search: t(lang.みその公正マーク) + "みそのこうせいまーく", authority: t(langA.全国味噌業公正取引協議会) },
  { name: t(lang.うに食品の公正マーク), search: t(lang.うに食品の公正マーク) + "うにしょくひんのこうせいまーく", authority: t(langA.全国うに食品公正取引協議会) },
  { name: t(lang.ローヤルゼリーの公正マーク), search: t(lang.ローヤルゼリーの公正マーク) + "ろーやるぜりーのこうせいまーく", authority: t(langA.社団法人全国ローヤルゼリー公正取引協議会) },
  { name: t(lang.レギュラーインスタントコーヒーの公正マーク), search: t(lang.レギュラーインスタントコーヒーの公正マーク) + "", authority: t(langA.全日本コーヒー公正取引協議会) },
  { name: t(lang.食品のりの公正マーク), search: t(lang.食品のりの公正マーク) + "しょくひんのりのこうせいまーく", authority: t(langA.食品のり公正取引協議会) },
  { name: t(lang.生産情報公表JASマーク), search: t(lang.生産情報公表JASマーク) + "せいさんじょほうこうひょうじゃすまーく", authority: t(langA.日本農林規格) },
  { name: t(lang.条件付き特定保健用食品マーク), search: t(lang.条件付き特定保健用食品マーク) + "じょうけんつきとくていほけんようしょくひんまーく", authority: t(langA.消費者庁) },
  { name: t(lang.FSC認証), search: t(lang.FSC認証) + "えふえすしーにんしょう", authority: t(langA.森林管理協議会) },
  { name: t(lang.CFP認証), search: t(lang.CFP認証) + "しーえふぴーにんしょう", authority: t(langA.産業環境管理協会) },
  { name: t(lang.MSC認証), search: t(lang.MSC認証) + "えむえすしーにんしょう", authority: t(langA.海洋管理協議会) },
  { name: t(lang.ドレッシング類の公正マーク), search: t(lang.ドレッシング類の公正マーク) + "どれっしんぐるいのこうせいまーく", authority: t(langA.全国ドレッシング類公正取引協議会) },
  { name: t(lang.ユニバーサルデザインフード), search: t(lang.ユニバーサルデザインフード) + "", authority: t(langA.日本介護食品協議会) },
  { name: t(lang.GIマーク), search: t(lang.GIマーク) + "じーあいちりてきひょうじまーく", authority: t(langA.農林水産省) },
]

export const CertificationList = [
  {name: "スチール缶材質表示マーク", synonym: "スチール缶材質表示マーク,すちーるかんざいしつひょうじまーく"},
  {name: "アルミ缶材質表示マーク", synonym: "アルミ缶材質表示マーク,あるみかんざいしつひょうじまーく"},
  {name: "PETボトル表示マーク", synonym: "PETボトル表示マーク,ぺっとぼとるひょうじまーく"},
  {name: "紙製容器包装マーク", synonym: "紙製容器包装マーク,かみせいようきほうそうまーく"},
  {name: "プラスチック製容器包装マーク", synonym: "プラスチック製容器包装マーク,ぷらすちっくせいようきほうそうまーく"},
  {name: "飲料用紙製容器マーク", synonym: "飲料用紙製容器マーク,いんりょうようかみせいようきまーく"},
  {name: "段ボールの国際的シンボルマーク", synonym: "段ボールの国際的シンボルマーク,だんぼーるのこくさいてきしんぼるまーく"},
  {name: "エコマーク", synonym: "エコマーク,"},
  {name: "統一美化マーク(リサイクルマーク）", synonym: "統一美化マーク(リサイクルマーク）,とういつびかまーく"},
  {name: "JASマーク", synonym: "JASマーク,じゃすまーく"},
  {name: "有機JASマーク", synonym: "有機JASマーク,ゆうきじゃすまーく"},
  {name: "特定JASマーク", synonym: "特定JASマーク,とくていじゃすまーく"},
  {name: "飲用乳の公正マーク", synonym: "飲用乳の公正マーク,いんようにゅうのこうせいまーく"},
  {name: "再生紙使用マーク（Rマーク）", synonym: "再生紙使用マーク（Rマーク）,さいせいししようまーくあーるまーく"},
  {name: "グリーンマーク", synonym: "グリーンマーク,"},
  {name: "プラスチック材質識別マーク1（SPIコード）", synonym: "プラスチック材質識別マーク1（SPIコード）,ぷらすちっくざいしきべつまーくえすぴーあいこーど"},
  {name: "プラスチック材質識別マーク2（SPIコード）", synonym: "プラスチック材質識別マーク2（SPIコード）,ぷらすちっくざいしきべつまーくえすぴーあいこーど"},
  {name: "プラスチック材質識別マーク3（SPIコード）", synonym: "プラスチック材質識別マーク3（SPIコード）,ぷらすちっくざいしきべつまーくえすぴーあいこーど"},
  {name: "プラスチック材質識別マーク4（SPIコード）", synonym: "プラスチック材質識別マーク4（SPIコード）,ぷらすちっくざいしきべつまーくえすぴーあいこーど"},
  {name: "プラスチック材質識別マーク5（SPIコード）", synonym: "プラスチック材質識別マーク5（SPIコード）,ぷらすちっくざいしきべつまーくえすぴーあいこーど"},
  {name: "プラスチック材質識別マーク6（SPIコード）", synonym: "プラスチック材質識別マーク6（SPIコード）,ぷらすちっくざいしきべつまーくえすぴーあいこーど"},
  {name: "プラスチック材質識別マーク7（SPIコード）", synonym: "プラスチック材質識別マーク7（SPIコード）,ぷらすちっくざいしきべつまーくえすぴーあいこーど"},
  {name: "JISマーク", synonym: "JISマーク,じすまーく"},
  {name: "特別用途食品マーク", synonym: "特別用途食品マーク,とくべつようとしょくひんまーく"},
  {name: "特定保健用食品マーク", synonym: "特定保健用食品マーク,とくていほけんようしょくひんまーく"},
  {name: "JHFAマーク", synonym: "JHFAマーク,じぇーえいちえふえーまーく"},
  {name: "環境に配慮した商品", synonym: "環境に配慮した商品,かんきょうにはいりょしたしょうひん"},
  {name: "PETボトルリサイクル推奨マーク", synonym: "PETボトルリサイクル推奨マーク,ぺっとぼとるりさいくるすいしょうまーく"},
  {name: "米の認証マーク", synonym: "米の認証マーク,こめのにんしょうまーく"},
  {name: "精米表示検定済みマーク", synonym: "精米表示検定済みマーク,せいまいひょうじけんていずみまーく"},
  {name: "無洗米認証マーク", synonym: "無洗米認証マーク,むせんまいにんしょうまーく"},
  {name: "HACCP", synonym: "HACCP,はさっぷ"},
  {name: "日本冷凍食品協会", synonym: "日本冷凍食品協会,確認工場製品　認定証マーク	にほんれいとうしょくひんきょうかいかくにんこうじょうせいひんにんていしょうまーく"},
  {name: "ふるさと認証食品（Eマーク）", synonym: "ふるさと認証食品（Eマーク）,ふるさとにんしょうしょくひんいーまーく"},
  {name: "生めんの公正マーク", synonym: "生めんの公正マーク,なまめんのこうせいまーく"},
  {name: "辛子めんたいこ食品の公正マーク", synonym: "辛子めんたいこ食品の公正マーク,からしめんたいこしょくひんのこうせいまーく"},
  {name: "ハム・ソーセージ類の公正マーク", synonym: "ハム・ソーセージ類の公正マーク,はむそーせーじるいのこうせいまーく"},
  {name: "SQマーク", synonym: "SQマーク,えすきゅーまーく"},
  {name: "塩の安全衛生基準認定マーク", synonym: "塩の安全衛生基準認定マーク,しおのあんぜんえいせいきじゅんにんていまーく"},
  {name: "はちみつの公正マーク", synonym: "はちみつの公正マーク,はちみつのこうせいまーく"},
  {name: "みその公正マーク", synonym: "みその公正マーク,みそのこうせいまーく"},
  {name: "うに食品の公正マーク", synonym: "うに食品の公正マーク,うにしょくひんのこうせいまーく"},
  {name: "ローヤルゼリーの公正マーク", synonym: "ローヤルゼリーの公正マーク,ろーやるぜりーのこうせいまーく"},
  {name: "レギュラー・インスタントコーヒーの公正マーク", synonym: "レギュラー・インスタントコーヒーの公正マーク,"},
  {name: "食品のりの公正マーク", synonym: "食品のりの公正マーク,しょくひんのりのこうせいまーく"},
  {name: "生産情報公表JASマーク", synonym: "生産情報公表JASマーク,せいさんじょほうこうひょうじゃすまーく"},
  {name: "条件付き特定保健用食品マーク", synonym: "条件付き特定保健用食品マーク,じょうけんつきとくていほけんようしょくひんまーく"},
  {name: "FSC認証", synonym: "FSC認証,えふえすしーにんしょう"},
  {name: "CFP認証", synonym: "CFP認証,しーえふぴーにんしょう"},
  {name: "MSC認証", synonym: "MSC認証,えむえすしーにんしょう"},
  {name: "ドレッシング類の公正マーク", synonym: "ドレッシング類の公正マーク,どれっしんぐるいのこうせいまーく"},
  {name: "ユニバーサルデザインフード", synonym: "ユニバーサルデザインフード,"},
  {name: "GI（地理的表示）マーク", synonym: "GI（地理的表示）マーク,じーあいちりてきひょうじまーく"},
]

export const Authorities = [
  "スチール缶リサイクル協会",
  "アルミ缶リサイクル協会",
  "PETボトルリサイクル推進協議会",
  "紙製容器包装リサイクル推進協議会",
  "プラスチック容器包装リサイクル推進協議会",
  "飲料用紙容器リサイクル協議会",
  "段ボールリサイクル協議会",
  "日本環境協会",
  "食品容器環境美化協会",
  "日本農林規格",
  "全国飲用牛乳公正取引協議会",
  "ごみ減量化推進国民会議",
  "古紙再生促進センター",
  "米国プラスチック産業協会（SPI)",
  "日本工業標準調査会",
  "消費者庁",
  "日本健康・栄養食品協会",
  "日本生活協同組合連合会",
  "日本穀物検定協会",
  "全国無洗米協会",
  "厚生労働大臣承認",
  "社団法人　日本冷凍食品協会",
  "各都道府県",
  "全国生めん類公正取引協議会",
  "全国辛子めんたいこ食品公正取引協議会",
  "ハム・ソーセージ類公正取引協議会",
  "社団法人　菓子総合技術センター",
  "社団法人　日本塩工会",
  "全国はちみつ公正取引協議会",
  "全国味噌業公正取引協議会",
  "全国うに食品公正取引協議会",
  "社団法人全国ローヤルゼリー公正取引協議会",
  "全日本コーヒー公正取引協議会",
  "食品のり公正取引協議会",
  "日本農林規格",
  "消費者庁",
  "森林管理協議会",
  "産業環境管理協会",
  "海洋管理協議会",
  "全国ドレッシング類公正取引協議会",
  "日本介護食品協議会",
  "農林水産省",
]
