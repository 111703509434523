import {getTextByLang as t} from "@/bootstraps/locale";

export const StorageTemperatureRangeTypeLang = {
  '常温': t({
    ja: "常温",
    en: "Room temperature",
    cn: "常温"
  }),
  '常温（25℃以下）': t({
    ja: "常温（25℃以下）",
    en: "Room temperature (below 25°C)",
    cn: "常温（25℃以下）"
  }),
  '常温（28℃以下）': t({
    ja: "常温（28℃以下）",
    en: "Room temperature (below 28°C)",
    cn: "常温（28℃以下）"
  }),
  '冷蔵': t({
    ja: "冷蔵",
    en: "Refrigerated",
    cn: "冷藏"
  }),
  '冷蔵（15℃以下）': t({
    ja: "冷蔵（15℃以下）",
    en: "Refrigerated (15℃ or less)",
    cn: "冷藏（15℃以下）"
  }),
  '冷蔵（10℃以下）': t({
    ja: "冷蔵（10℃以下）",
    en: "Refrigerated (10℃ or less)",
    cn: "冷藏（10℃以下）"
  }),
  '冷蔵（4℃以下）': t({
    ja: "冷蔵（4℃以下）",
    en: "Refrigerated (4℃ or less)",
    cn: "冷藏（4℃以下）"
  }),
  '冷蔵（-5℃以下）': t({
    ja: "冷蔵（-5℃以下）",
    en: "Refrigerated (-5℃ or less)",
    cn: "冷藏（-5℃以下）"
  }),
  '冷凍': t({
    ja: "冷凍",
    en: "Frozen",
    cn: "冷冻"
  }),
  '冷凍（-15℃以下）': t({
    ja: "冷凍（-15℃以下）",
    en: "Frozen (-15℃ or less)",
    cn: "冷冻（-15℃以下）"
  }),
  '冷凍（-18℃以下）': t({
    ja: "冷凍（-18℃以下）",
    en: "Frozen (-18℃ or less)",
    cn: "冷冻（-18℃以下）"
  }),
  '常温（20℃以下）': t({
    ja: "常温（20℃以下）",
    en: "Room temperature (20°C or less)",
    cn: "常温（20℃以下）"
  }),
  '冷蔵（2℃以下）': t({
    ja: "冷蔵（2℃以下）",
    en: "Refrigerated (2℃ or less)",
    cn: "冷藏（2℃以下）"
  }),
  '冷蔵（0℃以下）': t({
    ja: "冷蔵（0℃以下）",
    en: "Refrigerated (0℃ or less)",
    cn: "冷藏（0℃以下）"
  }),
  '冷凍（-30℃以下）': t({
    ja: "冷凍（-30℃以下）",
    en: "Frozen (-30℃ or less)",
    cn: "冷冻（-30℃以下）"
  }),
  '冷凍（-40℃以下）': t({
    ja: "冷凍（-40℃以下）",
    en: "Frozen (-40℃ or less)",
    cn: "冷冻（-40℃以下）"
  }),
  '冷凍（-50℃以下）': t({
    ja: "冷凍（-50℃以下）",
    en: "Frozen (-50℃ or less)",
    cn: "冷冻（-50℃以下）"
  }),
};
