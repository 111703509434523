import {getTextByLang as t} from "@/bootstraps/locale";

export const XRayDetectionMaterialsLang = {
  '鉄（Fe）': t({
    ja: "鉄（Fe）",
    en: "Iron（Fe）",
    cn: "铁（Fe）"
  }),
  'ステンレス（SUS）': t({
    ja: "ステンレス（SUS）",
    en: "Stainless",
    cn: "防锈的（SUS）"
  }),
  'アルミニウム': t({
    ja: "アルミニウム",
    en: "Aluminium",
    cn: "铝"
  }),
  'ガラス（石英）': t({
    ja: "ガラス（石英）",
    en: "Glass（Quartz）",
    cn: "玻璃（石英）"
  }),
  'EPDMゴム': t({
    ja: "EPDMゴム",
    en: "EPDM rubber",
    cn: "三元乙丙橡胶"
  }),
  'フッ素樹脂': t({
    ja: "フッ素樹脂",
    en: "Fluororesin",
    cn: "氟树脂"
  }),
  'ガラス（ソーダ）': t({
    ja: "ガラス（ソーダ）",
    en: "Glass（Soda）",
    cn: "苏打玻璃"
  }),
  '石': t({
    ja: "石",
    en: "Stone",
    cn: "石"
  }),
  'セラミック': t({
    ja: "セラミック",
    en: "Ceramic",
    cn: "陶瓷制品"
  }),
  '貝殻': t({
    ja: "貝殻",
    en: "Shell",
    cn: "壳"
  }),
  '骨': t({
    ja: "骨",
    en: "Bone",
    cn: "骨"
  }),
};
