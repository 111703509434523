import addSeconds from "date-fns/addSeconds";

export default class CustomCache<T extends any> {
  public value: T | null = null;
  private expiredAt:Date = new Date;

  public set(value:T, cacheExpirationSeconds:number) {
    this.expiredAt = addSeconds(new Date, cacheExpirationSeconds);
    this.value = value;
  }

  public get(): T|null {
    if (!this.isCacheValid) {
      this.clear();
    }
    return this.value;
  }

  public clear() {
    this.value = null;
  }

  public get isCacheValid() {
    return !!this.value && this.expiredAt > (new Date);
  }

  public async getOrFetch(fetchFunction, cacheExpirationSeconds:number): Promise<T> {
    const cache = this.get();
    if (cache) {
      return cache;
    }

    const res = await fetchFunction();
    this.set(res, cacheExpirationSeconds);
    return res;
  }

}
