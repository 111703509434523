import toNumber from "lodash/toNumber";
import round from "lodash/round";
import toFixed from "xe-utils/toFixed";
import isNumber from "lodash/isNumber";
import ceil from "lodash/ceil";

export enum NutritionSpecialValue {
  None = '-',
  Tr = 'Tr'
}

/**
 *
 * accepted values
 ""|null|undefined: blank
 -: NullValue
 n: Pure
   (n): Pure (estimated)
   [n]: Pure (computedEstimated)
 Tr: Tr
   (Tr): Tr  (estimated)
   [Tr]:  (computedEstimated)
 */

export enum NutritionValueType {
  Blank,
  None,
  PureNumber,
  PureNumberWithParenthesis,
  PureNumberWithBracket,
  Tr,
  TrWithParenthesis,
  TrWithBracket,
}

export class NutritionValue {
  public original:string|null = null;

  public static readonly Blank = new NutritionValue('');
  public static readonly None = new NutritionValue(NutritionSpecialValue.None);
  public static readonly Tr = new NutritionValue(NutritionSpecialValue.Tr);

  public constructor(
    value:string|null,
    public readonly key:string|null = null,
    public readonly perAmount = 100,
    public readonly withTr = false,
  ) {
    if(value !== null && isNumber(value)) {
      value = (value as number).toString();
    }
    this.original = value;
  }

  public equal(target:NutritionValue|null) {
    if (!target) return this.isBlank;

    return (this.isBlank && target.isBlank) ||
      (this.isNone && target.isNone) ||
      (this.isTr && target.isTr) ||
      (this.original === target.original);
  }

  public get numberString(): string| null {
    if (!this.isCalculatable) return null;
    if (this.isBlank) return null;
    const res = this.original!.match(/[\d\.eE+-]+/g);
    if (!res) return null;
    return res[0];
  }
  public get number():number|null {
    if (!this.isCalculatable) return null;
    if (this.isBlank) return 0;
    const res = this.original!.match(/[\d\.eE+-]+/g);
    return toNumber(res);
  }
  private getValuePer100g(): NutritionValue {
    return NutritionValue.calc([this], () => this.number! * (100 / this.perAmount), this.key, 100);
  }

  public get isBlank():boolean {
    return this.original === '' || this.original === null || this.original === undefined;
  }
  public get isNone():boolean {
    return this.original === NutritionSpecialValue.None;
  }
  public get isNoneOrBlank(): boolean {
    return this.isBlank || this.isNone;
  }
  public get isExactTr():boolean {
    if (this.isBlank) return false;
    return this.original!.match(/^tr$/iu) !== null;
  }
  public get isTr():boolean {
    if (this.isBlank) return false;
    return this.isExactTr ||
      this.original!.match(/^[\[［]tr[\]］]$/iu) !== null ||
      this.original!.match(/^[\(（]tr[\)）]$/iu) !== null;
  }
  public get containsTr(): boolean {
    return this.isTr || this.withTr;
  }

  private get isPure():boolean {
    if (this.isBlank) return false;
    return this.original!.match(/^([1-9]\d*|0)(\.\d+)?([eE][+-]?\d+)?$/iu) !== null;
  }
  public get isEstimated():boolean {
    if (this.isBlank) return false;
    return this.original!.match(/^[\(（]tr[\)）]$/iu) !== null ||
      this.original!.match(/^[\(（]([1-9]\d*|0)(\.\d+)?([eE][+-]?\d+)?[\)）]$/iu) !== null;
  }
  public get isComputedEstimated():boolean {
    if (this.isBlank) return false;
    return this.original!.match(/^[\[［]tr[\]］]$/iu) !== null ||
      this.original!.match(/^[\[［]([1-9]\d*|0)(\.\d+)?([eE][+-]?\d+)?[\]］]$/iu) !== null;
  }

  public get isCalculatable():boolean {
    return this.isTr || this.isBlank || this.isPure || this.isEstimated || this.isComputedEstimated;
  }
  public get isValid():boolean {
    return this.isTr || this.isBlank || this.isPure || this.isEstimated || this.isComputedEstimated;
  }

  public getSaltValueOfNatrium():NutritionValue {
    return NutritionValue.calc([this], () => this.number! * 2.54 / 1000, 'salt', this.perAmount);
  }
  public getNatriumValueOfSalt():NutritionValue {
    return NutritionValue.calc([this], () => this.number! / 2.54 * 1000, 'natrium', this.perAmount);
  }

  public formatForRaw(precision = 2, toLocale: boolean = true):string {
    if (this.isNoneOrBlank) return NutritionSpecialValue.None;
    if (this.isTr) return this.original!;

    let val = this.getRoundedValue(precision);
    if (isNumber(val)) {
      if (toLocale) {
        val = (val as number).toLocaleString(undefined, { maximumFractionDigits: precision }).replace(/,/g, '');
      } else {
        val = (val as number).toFixed(precision);
        val = val.replace(/^(.*?)(0+)$/g, "$1"); // trim trailing zero
      }
    }

    return NutritionValue.wrapWithParenthesis(val, [this]);
  }
  public formatForLabelPrint(precision = 2, perAmount = 100):string {
    if (this.isNoneOrBlank) return NutritionSpecialValue.None;
    if (this.isTr) return Number('0').toFixed(precision);

    let res = this.getRoundedValue(precision);
    if (isNumber(res)) {
      return (res as number).toFixed(precision);
    } else {
      return res;
    }
  }
  private getRoundedValue(precision:number): string | number {
    // https://www.caa.go.jp/policies/policy/food_labeling/nutrient_declearation/business/assets/food_labeling_cms206_20220531_08.pdf
    // P.26 （３）表示値の桁数
    // たんぱく質、脂質、飽和脂肪酸、コレステロール、炭水化物、糖質、糖類、食塩相当量
    // 上記は、100g当たりで「0と表示することができる量」以上ある場合、
    // 食品単位当たりの表示値において最小表示の位に満たない場合であっても、「０」と表示はできません。
    // 表示の位を下げ、有効数字１桁以上表示してください。（四捨五入)
    // ※ 0.0012の有効数字1桁表示 = 0.001、 0.0016の有効数字1桁表示 = 0.002
    //
    // 「0と表示することができる量」は以下「食品表示基準別表第９第５欄」
    // https://elaws.e-gov.go.jp/document?lawid=427M60000002010
    // 熱量: 5kcal
    // たんぱく質, 脂質, 炭水化物、糖質、糖類: 0.5g
    // 飽和脂肪酸: 0.1g
    // 食塩相当量: ナトリウム換算5mg（食塩相当量換算0.0127g） => 0.01gまたは0.013gまたは0.0127gと表示する。(現在の設定では0.00が最小表示のため、0.01を返す)
    // コレステロール: 5mg

    const roundedValue = round(this.number!, precision) as number;
    if (roundedValue === 0) {
      const valuePer100g = this.getValuePer100g();

      if (
        (['calorie'].includes(this.key!) && valuePer100g.number! >= 5) ||
        (['protein', 'lipid', 'carb', 'sugar', 'saccharides'].includes(this.key!) && valuePer100g.number! >= 0.5)
        // } else if (this.key === 'saturatedFattyAcids' && valuePer100g.number! >= 0.1) {
        // } else if (this.key === 'cholesterol' && valuePer100g.number! >= 5) {
      ) {
        return this.number!.toPrecision(1);
      } else if ((this.key === 'salt' || this.key === 'saltValue') && valuePer100g.number! >= 0.0127) {
        return this.number!.toPrecision(1);
      }
    }

    return roundedValue;
  }

  public static wrapWithParenthesis(val:number|string, vals:NutritionValue[]): string {
    if (vals.some(v => v.isComputedEstimated)) return `[${val}]`;
    if (vals.filter(v => v.isEstimated).length >= 2) return `[${val}]`;
    if (vals.filter(v => v.isEstimated).length === 1) return `(${val})`;
    return val.toString();
  }

  public static calc(vals:NutritionValue[], func:(vals:NutritionValue[]) => number|string|null, key: string | null = null, perAmount = 100):NutritionValue {
    if(vals.some(v => !v.isCalculatable)) return NutritionValue.None;

    const val = vals.every(v => v.isTr) ?
      NutritionSpecialValue.Tr :
      func(vals.filter(v => !v.containsTr));

    const trimmedVal = val === null ? NutritionSpecialValue.None : val;
    const res = NutritionValue.wrapWithParenthesis(trimmedVal, vals);
    const containTr = vals.some(v => v.containsTr);
    return new NutritionValue(res, key, perAmount, containTr);
  }
}
