import {NutritionValue} from "@/entities/nutrition-value";
import pick from "lodash/pick";

export enum NutritionExtraProp {
  Alcohol = 'alcohol', // アルコール
  Caffeine = 'caffeine', // カフェイン
  MilkFat = 'milk_fat', // 乳脂肪分
  MilkNonFadSolid = 'milk_non_fat_solid', // 無脂乳固形分
  EggFat = 'egg_fat', // 卵脂肪分
  VegetableFat = 'vegetable_fat', // 植物性脂肪分
  SoySolid = 'soy_solid', // 大豆固形分
}

export interface INutritionExtra {
  prop: NutritionExtraProp;
  value: NutritionValue;
}
export interface IHasNutritionExtra {
  nutritionExtras: NutritionExtra[];
}
export class NutritionExtra implements INutritionExtra {
  public prop!: NutritionExtraProp;
  public value!: NutritionValue;

  constructor(init: Partial<NutritionExtra> = {}) {
    this.prop = init.prop!;
    this.value = init.value || new NutritionValue('');
  }

  public static parseFromApi(data: { prop: NutritionExtraProp, value: string | null }) {
    return new NutritionExtra({
      prop: data.prop,
      value: new NutritionValue(data.value, data.prop),
    });
  }
  public serialize() {
    return {
      prop: this.prop,
      value: this.value.original,
    };
  }
  public get label(): string {
    return NutritionExtraPropDict[this.prop].label;
  }
  public get unit(): string {
    return NutritionExtraPropDict[this.prop].unit;
  }
  public get minDecimal(): number {
    return NutritionExtraPropDict[this.prop].minDecimal;
  }
  public get isAlcohol(): boolean {
    return this.prop === NutritionExtraProp.Alcohol;
  }
}

export const NutritionExtraPropDict = {
  [NutritionExtraProp.Alcohol]: { label: 'アルコール', unit: '%', minDecimal: 1 },
  [NutritionExtraProp.MilkFat]: { label: '乳脂肪分', unit: '%', minDecimal: 1 },
  [NutritionExtraProp.MilkNonFadSolid]: { label: '無脂乳固形分', unit: '%', minDecimal: 1 },
  [NutritionExtraProp.EggFat]: { label: '卵脂肪分', unit: '%', minDecimal: 1 },
  [NutritionExtraProp.VegetableFat]: { label: '植物性脂肪分', unit: '%', minDecimal: 1 },
  [NutritionExtraProp.SoySolid]: { label: '大豆固形分', unit: '%', minDecimal: 1 },
  [NutritionExtraProp.Caffeine]: { label: 'カフェイン', unit: 'mg', minDecimal: 0 },
};
