import ObjectUtils from "@/utils/object-utils";
import ProductEntity from "@/entities/product-entity";

export interface IBasicOption {
  isWaterOmitted:boolean;
}
export interface ICompositeOption {
  isFewCompositeItemsOmitted:boolean;
  isManyCompositeItemsOmitted:boolean;
}
export interface IAllergenOption {
  isAllergenSummarized:boolean;
  isRepeatedAllergenOmitted:boolean;
}
export interface IAdditiveOption {
  isThickenerAdditiveGrouped:boolean;
  isSameFunctionAdditiveGrouped:boolean;
  isColoringPurposeVisible: boolean;
}
export interface IMadeInAreaOption {
  isMadeInAreasManyForeignSummarized:boolean;
  isMadeInAreasDomesticSummarized:boolean;
  isManyMadeInAreasOmitted:boolean;
  isMadeInAreasRatioVariable: boolean;
}


export default class ProductDisplaySetting implements IBasicOption, ICompositeOption, IAdditiveOption, IAllergenOption, IMadeInAreaOption{
  public id!: number| null;

  // アレルゲンの一括表示
  public isAllergenSummarized: boolean = true;

  // アレルゲンを繰り返し表示しない
  public isRepeatedAllergenOmitted: boolean = true;

  // 複合原材料において3種類以上の原材料があり、3位以下で5％未満のものは「その他」と表示する
  public isManyCompositeItemsOmitted: boolean = true;

  // 該当の複合原材料が、原材料全体の5％未満の場合は、複合原材料の後ろの（）書きを省略する
  public isFewCompositeItemsOmitted: boolean = true;

  // 増粘剤をまとめる
  public isThickenerAdditiveGrouped: boolean = true;

  // 同種の機能をまとめる
  public isSameFunctionAdditiveGrouped: boolean = true;

  public isManyMadeInAreasOmitted: boolean = true; // 3カ国目以降を「豚肉（アメリカ、カナダ、その他）」のように「その他」と表示する
  public isMadeInAreasSeparated: boolean = false; // 原料原産地名を別途欄に表示する
  public isMadeInAreasRatioVariable: boolean = true; // (又は表示) 産地国の使用割合が変動する
  public isMadeInAreasManyForeignSummarized: boolean = true; // (大括り表示) 海外3ヵ国以上の産地を輸入としてまとめる
  public isMadeInAreasDomesticSummarized: boolean = true; // (国内大括り表示) 産地が国内のものを国産・国内製造に統一

  // カタカナを半角にする
  public isKatakanaAsHankaku:boolean = false;

  public isColoringPurposeVisible: boolean = true; // 着色料の省略設定

  public showOnlyMinGmos: boolean = false; // GMOの表示を省略する

  // 水の表示を省略する
  public isWaterOmitted: boolean = true;

  // 還元・水戻し後重量で表示順に反映する
  public calcWithReducedWeight: boolean = false;

  public shouldMadeInAreasSeparated(product: ProductEntity) {
    if (product.isAssort) return false;
    return this.isMadeInAreasSeparated;
  }

  constructor(init:Partial<ProductDisplaySetting> = {}) {
    ObjectUtils.assignLiteralFields(this, init);
  }
}

