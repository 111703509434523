import ObjectUtils from "@/utils/object-utils";
import {
  ApprovalFlowSettingStepCompletionType,
  ApprovalFlowSettingStepCompletionTypeDict,
  IApprovalStep,
  IApprovalStepGroup,
  IApprovalStepGroupUser
} from "@/entities/approval-flow-setting-entity";
import sum from "lodash/sum";

export interface IHasApprovalRequest {
  approvalRequest: ApprovalRequestEntity | null;
}

export class ApprovalRequestEntity {
  public id!:number;
  public name!: string;
  public comment!: string | null;

  public steps: ApprovalRequestStepEntity[] = [];

  public get isCompleted(): boolean {
    return this.steps.every(s => s.isCompleted);
  }

  public getCurrentStep(): ApprovalRequestStepEntity {
    for(const s of this.steps) {
      if (!s.isCompleted) return s;
    }
    return this.steps[0];
  }
  public getCurrentStepNumber(): number {
    return this.steps.indexOf(this.getCurrentStep()) + 1;
  }
  public getTotalStepNumber(): number {
    return this.steps.length;
  }
  public getAllGroupUsers(): ApprovalRequestStepGroupUserEntity[] {
    return this.steps.flatMap(s => s.getGroupUsers());
  }
  public get isCurrentStepLast(): boolean {
    return this.getTotalStepNumber() === this.getCurrentStepNumber();
  }

  constructor(init:Partial<ApprovalRequestEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);

    if(init.steps) {
      this.steps = init.steps.map(step => new ApprovalRequestStepEntity(step));
    }
  }
}

export class ApprovalRequestStepEntity implements IApprovalStep {
  public id!:number;
  public name!: string;
  public completionConditionType!: ApprovalFlowSettingStepCompletionType;

  public groups: ApprovalRequestStepGroupEntity[] = [];

  constructor(init:Partial<ApprovalRequestStepEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);

    if(init.groups) {
      this.groups = init.groups.map(group => new ApprovalRequestStepGroupEntity(group));
    }
  }
  public get completionConditionTypeLabel(): string {
    return ApprovalFlowSettingStepCompletionTypeDict[this.completionConditionType];
  }

  public getGroupUsers(): ApprovalRequestStepGroupUserEntity[] {
    return this.groups.flatMap(g => g.groupUsers);
  }
  public findGroupByUser(userId: number): ApprovalRequestStepGroupEntity | null{
    for(const g of this.groups) {
      for(const gu of g.groupUsers) {
        if (gu.userId === userId) return g;
      }
    }
    return null;
  }
  public isLastOneApproveRequired(userId:number): boolean {
    const targetGroup = this.findGroupByUser(userId)!;
    if (this.completionConditionType === ApprovalFlowSettingStepCompletionType.And) {
      // AND(すべての承認グループ)
      const otherGroups = this.groups.filter(g => g.id !== targetGroup.id);
      return targetGroup.isLastOneApprovalRequired && otherGroups.every(g => g.isCompleted);
    } else {
      // OR（いずれか一つの承認グループの完了）
      return targetGroup.isLastOneApprovalRequired;
    }
  }
  public get isCompleted(): boolean {
    switch (this.completionConditionType) {
      case ApprovalFlowSettingStepCompletionType.And:
        return this.groups.every((group) => group.isCompleted);
      case ApprovalFlowSettingStepCompletionType.Or:
        return this.groups.some((group) => group.isCompleted);
      default:
        return false;
    }
  }
}

export class ApprovalRequestStepGroupEntity implements IApprovalStepGroup {
  public id!:number;
  public requiredApprovalNumber!: number | null; // nullの場合は全員が必要

  public groupUsers: ApprovalRequestStepGroupUserEntity[] = [];
  public users: IApprovalStepGroupUser[] = [];

  constructor(init:Partial<ApprovalRequestStepGroupEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);

    this.groupUsers = (init.groupUsers || []).map(u => new ApprovalRequestStepGroupUserEntity(u));
    this.users = (init.groupUsers || []).map(u => new ApprovalRequestStepGroupUserEntity(u));
  }

  public get isAllRequired() {
    return this.requiredApprovalNumber === null;
  }

  public get requiredApprovalCount(): number {
    return this.requiredApprovalNumber || this.users.length;
  }
  public get approvedCount(): number {
    return this.groupUsers.filter(u => u.approved).length;
  }
  public get isLastOneApprovalRequired(): boolean {
    if (this.isAllRequired) {
      return this.groupUsers.length - this.approvedCount === 1;
    } else {
      return this.requiredApprovalNumber! - this.approvedCount === 1;
    }
  }
  public get isCompleted(): boolean {
    if (this.isAllRequired) {
      return this.groupUsers.every(u => u.approved);
    } else {
      return this.groupUsers.filter(u => u.approved).length === this.requiredApprovalNumber!;
    }
  }
}

export class ApprovalRequestStepGroupUserEntity implements IApprovalStepGroupUser {
  public id!:number;
  public userId!: number;
  public userName!: string;
  public approvedAt!: Date | null;

  constructor(init:Partial<ApprovalRequestStepGroupEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);
  }
  public get approved(): boolean {
    return this.approvedAt !== null;
  }
}
