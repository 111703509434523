export const SpecPackageCertificationMarkLang = {
  'スチール缶材質表示マーク': {
    ja: "スチール缶材質表示マーク",
    en: "Steel Can Material Marking",
    cn: "钢罐材料标记标记"
  },
  'アルミ缶材質表示マーク': {
    ja: "アルミ缶材質表示マーク",
    en: "Aluminum Can Material Marking",
    cn: "铝罐材料标记标记"
  },
  'PETボトル表示マーク': {
    ja: "PETボトル表示マーク",
    en: "PET Bottle Labeling Mark",
    cn: "PET瓶标签标识"
  },
  '紙製容器包装マーク': {
    ja: "紙製容器包装マーク",
    en: "Paper Container and Packaging Mark",
    cn: "纸质容器和包装标志"
  },
  'プラスチック製容器包装マーク': {
    ja: "プラスチック製容器包装マーク",
    en: "Plastic Container and Packaging Mark",
    cn: "塑料容器和包装标志"
  },
  '飲料用紙製容器マーク': {
    ja: "飲料用紙製容器マーク",
    en: "Paper beverage container mark",
    cn: "饮料纸容器标志"
  },
  '段ボールの国際的シンボルマーク': {
    ja: "段ボールの国際的シンボルマーク",
    en: "International symbol for corrugated cardboard",
    cn: "瓦楞纸板的国际符号"
  },
  'エコマーク': {
    ja: "エコマーク",
    en: "ecological mark",
    cn: "生态标志"
  },
  '統一美化マーク': {
    ja: "統一美化マーク(リサイクルマーク）",
    en: "Unified Beautification Mark (Recycle Mark)",
    cn: "统一美化标志（回收标志）"
  },
  'JASマーク': {
    ja: "JASマーク",
    en: "JAS mark",
    cn: "JAS标志"
  },
  '有機JASマーク': {
    ja: "有機JASマーク",
    en: "Organic JAS Mark",
    cn: "有机JAS标志"
  },
  '特定JASマーク': {
    ja: "特定JASマーク",
    en: "Specific JAS Mark",
    cn: "具体的JAS标志"
  },
  '飲用乳の公正マーク': {
    ja: "飲用乳の公正マーク",
    en: "Fair mark for drinking milk",
    cn: "喝牛奶的分数还不错"
  },
  '再生紙使用マーク': {
    ja: "再生紙使用マーク（Rマーク）",
    en: "Recycled paper use mark (R mark)",
    cn: "再生纸使用标记（R标记）"
  },
  'グリーンマーク': {
    ja: "グリーンマーク",
    en: "green mark",
    cn: "绿标"
  },
  'プラスチック材質識別マーク1': {
    ja: "プラスチック材質識別マーク1（SPIコード）",
    en: "Plastic material identification mark 1 (SPI code)",
    cn: "塑料材料识别标志1（SPI代码）"
  },
  'プラスチック材質識別マーク2': {
    ja: "プラスチック材質識別マーク2（SPIコード）",
    en: "Plastic material identification mark 2 (SPI code)",
    cn: "塑料材料识别标志2（SPI代码）"
  },
  'プラスチック材質識別マーク3': {
    ja: "プラスチック材質識別マーク3（SPIコード）",
    en: "Plastic material identification mark 3 (SPI code)",
    cn: "塑料材料识别标志3（SPI代码）"
  },
  'プラスチック材質識別マーク4': {
    ja: "プラスチック材質識別マーク4（SPIコード）",
    en: "Plastic material identification mark 4 (SPI code)",
    cn: "塑料材料识别标志4（SPI代码）"
  },
  'プラスチック材質識別マーク5': {
    ja: "プラスチック材質識別マーク5（SPIコード）",
    en: "Plastic material identification mark 5 (SPI code)",
    cn: "塑料材料识别标志5（SPI代码）"
  },
  'プラスチック材質識別マーク6': {
    ja: "プラスチック材質識別マーク6（SPIコード）",
    en: "Plastic material identification mark 6 (SPI code)",
    cn: "塑料材料识别标志6（SPI代码）"
  },
  'プラスチック材質識別マーク7': {
    ja: "プラスチック材質識別マーク7（SPIコード）",
    en: "Plastic material identification mark 7 (SPI code)",
    cn: "塑料材料识别标志7（SPI代码）"
  },
  'JISマーク': {
    ja: "JISマーク",
    en: "JIS mark",
    cn: "JIS标志"
  },
  '特別用途食品マーク': {
    ja: "特別用途食品マーク",
    en: "special-purpose food mark",
    cn: "特殊用途的食品标志"
  },
  '特定保健用食品マーク': {
    ja: "特定保健用食品マーク",
    en: "Food for Specified Health Use Mark",
    cn: "特定健康用途的食品"
  },
  'JHFAマーク': {
    ja: "JHFAマーク",
    en: "JHFA Mark",
    cn: "JHFA标志"
  },
  '環境に配慮した商品': {
    ja: "環境に配慮した商品",
    en: "Environmentally friendly products",
    cn: "环境友好型产品"
  },
  'PETボトルリサイクル推奨マーク': {
    ja: "PETボトルリサイクル推奨マーク",
    en: "PET Bottle Recycling Recommendation Mark",
    cn: "PET瓶回收推荐标识"
  },
  '米の認証マーク': {
    ja: "米の認証マーク",
    en: "Rice Certification Mark",
    cn: "大米的认证标志"
  },
  '精米表示検定済みマーク': {
    ja: "精米表示検定済みマーク",
    en: "Polished rice labeling certification mark",
    cn: "经过验证的碾压大米标签标识"
  },
  '無洗米認証マーク': {
    ja: "無洗米認証マーク",
    en: "No-rinse rice certification mark",
    cn: "免洗大米认证标志"
  },
  'HACCP': {
    ja: "HACCP",
    en: "HACCP",
    cn: "HACCP"
  },
  '日本冷凍食品協会確認工場製品認定証マーク': {
    ja: "日本冷凍食品協会　確認工場製品　認定証マーク",
    en: "Japan Frozen Foods Association Certificate Mark for products from verified factories",
    cn: "日本冷冻食品协会的证书标志，适用于经过验证的工厂的产品"
  },
  'ふるさと認証食品': {
    ja: "ふるさと認証食品（Eマーク）",
    en: "Hometown Certified Foods (E Mark)",
    cn: "家乡认证的食品（E-mark）"
  },
  '生めんの公正マーク': {
    ja: "生めんの公正マーク",
    en: "Fair mark for fresh noodles",
    cn: "新鲜面条的合理分数"
  },
  '辛子めんたいこ食品の公正マーク': {
    ja: "辛子めんたいこ食品の公正マーク",
    en: "Fair mark for spicy mentaiko food products",
    cn: "辣味明太子食品的公平标记"
  },
  'ハムソーセージ類の公正マーク': {
    ja: "ハム・ソーセージ類の公正マーク",
    en: "Fair mark for ham and sausage products",
    cn: "火腿和香肠的标记很合理"
  },
  'SQマーク': {
    ja: "SQマーク",
    en: "SQ Mark",
    cn: "SQ标志"
  },
  '塩の安全衛生基準認定マーク': {
    ja: "塩の安全衛生基準認定マーク",
    en: "Salt Safety and Health Standards Certification Mark",
    cn: "盐业健康和安全标准批准标志"
  },
  'はちみつの公正マーク': {
    ja: "はちみつの公正マーク",
    en: "Fair mark for honey",
    cn: "蜂蜜的评分尚可"
  },
  'みその公正マーク': {
    ja: "みその公正マーク",
    en: "Fair Mark of Miso",
    cn: "酱的公平标记"
  },
  'うに食品の公正マーク': {
    ja: "うに食品の公正マーク",
    en: "Fair mark for sea urchin food products",
    cn: "海胆食品的公平标记"
  },
  'ローヤルゼリーの公正マーク': {
    ja: "ローヤルゼリーの公正マーク",
    en: "Fair mark for royal jelly",
    cn: "蜂王浆的分数还不错"
  },
  'レギュラーインスタントコーヒーの公正マーク': {
    ja: "レギュラー・インスタントコーヒーの公正マーク",
    en: "Fair mark for regular and instant coffee",
    cn: "普通咖啡和速溶咖啡的评分合理"
  },
  '食品のりの公正マーク': {
    ja: "食品のりの公正マーク",
    en: "Fair mark for food glue",
    cn: "食品胶水的评分尚可"
  },
  '生産情報公表JASマーク': {
    ja: "生産情報公表JASマーク",
    en: "JAS Mark for Publication of Production Information",
    cn: "生产信息公布JAS标志"
  },
  '条件付き特定保健用食品マーク': {
    ja: "条件付き特定保健用食品マーク",
    en: "Conditional Food for Specified Health Use Mark",
    cn: "有条件的特定健康用途的食品标志"
  },
  'FSC認証': {
    ja: "FSC認証",
    en: "FSC Certification",
    cn: "FSC认证"
  },
  'CFP認証': {
    ja: "CFP認証",
    en: "CFP Certification",
    cn: "CFP认证"
  },
  'MSC認証': {
    ja: "MSC認証",
    en: "MSC Certification",
    cn: "MSC认证"
  },
  'ドレッシング類の公正マーク': {
    ja: "ドレッシング類の公正マーク",
    en: "Fair mark for dressings",
    cn: "敷料的评分尚可"
  },
  'ユニバーサルデザインフード': {
    ja: "ユニバーサルデザインフード",
    en: "Universal Design Food",
    cn: "通用设计食品"
  },
  'GIマーク': {
    ja: "GI（地理的表示）マーク",
    en: "GI (Geographical Indications) Mark",
    cn: "GI（地理标志）标志"
  },
};

export const SpecPackageCertificationMarkAuthorityLang = {
  'スチール缶リサイクル協会': {
    ja: "スチール缶リサイクル協会",
    en: "Steel Can Recycling Association",
    cn: "钢罐回收协会"
  },
  'アルミ缶リサイクル協会': {
    ja: "アルミ缶リサイクル協会",
    en: "Aluminum Can Recycling Association",
    cn: "铝罐回收协会"
  },
  'PETボトルリサイクル推進協議会': {
    ja: "PETボトルリサイクル推進協議会",
    en: "PET Bottle Recycling Promotion Council",
    cn: "PET瓶回收促进会"
  },
  '紙製容器包装リサイクル推進協議会': {
    ja: "紙製容器包装リサイクル推進協議会",
    en: "Paper Container and Packaging Recycling Promotion Council",
    cn: "纸容器和包装回收促进会"
  },
  'プラスチック容器包装リサイクル推進協議会': {
    ja: "プラスチック容器包装リサイクル推進協議会",
    en: "Council for Promotion of Recycling of Plastic Containers and Packaging",
    cn: "塑料容器和包装回收促进会"
  },
  '飲料用紙容器リサイクル協議会': {
    ja: "飲料用紙容器リサイクル協議会",
    en: "Beverage Paper Container Recycling Council",
    cn: "饮料纸容器回收委员会"
  },
  '段ボールリサイクル協議会': {
    ja: "段ボールリサイクル協議会",
    en: "Corrugated Cardboard Recycling Council",
    cn: "纸板回收委员会"
  },
  '日本環境協会': {
    ja: "日本環境協会",
    en: "Japan Environment Association",
    cn: "日本环境协会"
  },
  '食品容器環境美化協会': {
    ja: "食品容器環境美化協会",
    en: "Food Container Environmental Beautification Association",
    cn: "食品容器与环境美化协会"
  },
  '日本農林規格': {
    ja: "日本農林規格",
    en: "JAS",
    cn: "JAS"
  },
  '全国飲用牛乳公正取引協議会': {
    ja: "全国飲用牛乳公正取引協議会",
    en: "National Fair Trade Council of Drinking Milk",
    cn: "全国饮用奶公平贸易委员会"
  },
  'ごみ減量化推進国民会議': {
    ja: "ごみ減量化推進国民会議",
    en: "National Council of State Governments for Waste Reduction Promotion",
    cn: "国家减少废物促进委员会"
  },
  '古紙再生促進センター': {
    ja: "古紙再生促進センター",
    en: "Paper Recycling Promotion Center",
    cn: "再生纸推广中心"
  },
  '米国プラスチック産業協会': {
    ja: "米国プラスチック産業協会（SPI)",
    en: "Society of the Plastics Industry (SPI)",
    cn: "美国塑料工业协会 (SPI)"
  },
  '日本工業標準調査会': {
    ja: "日本工業標準調査会",
    en: "The Japan Industrial Standards Institute",
    cn: "日本工业标准协会"
  },
  '消費者庁': {
    ja: "消費者庁",
    en: "Consumer Affairs Agency",
    cn: "消费者事务局"
  },
  '日本健康栄養食品協会': {
    ja: "日本健康・栄養食品協会",
    en: "Japan Health and Nutrition Food Association",
    cn: "日本健康与营养食品协会"
  },
  '日本生活協同組合連合会': {
    ja: "日本生活協同組合連合会",
    en: "Japan Consumers' Co-operative Union",
    cn: "日本消费者合作联盟"
  },
  '日本穀物検定協会': {
    ja: "日本穀物検定協会",
    en: "Japan Grain Inspection Association",
    cn: "日本谷物检查协会"
  },
  '全国無洗米協会': {
    ja: "全国無洗米協会",
    en: "National Association of Unwashed Rice",
    cn: "全国不洗米协会"
  },
  '厚生労働大臣承認': {
    ja: "厚生労働大臣承認",
    en: "Approved by the Minister of Health, Labor and Welfare",
    cn: "由卫生、劳动和福利部长批准"
  },
  '社団法人日本冷凍食品協会': {
    ja: "社団法人　日本冷凍食品協会",
    en: "Japan Frozen Foods Association",
    cn: "日本冷冻食品协会"
  },
  '各都道府県': {
    ja: "各都道府県",
    en: "all prefectures",
    cn: "所有省份"
  },
  '全国生めん類公正取引協議会': {
    ja: "全国生めん類公正取引協議会",
    en: "Japan Fresh Noodles Fair Trade Council",
    cn: "全国新鲜面条公平贸易委员会"
  },
  '全国辛子めんたいこ食品公正取引協議会': {
    ja: "全国辛子めんたいこ食品公正取引協議会",
    en: "National Spicy Mentaiko Food Fair Trade Council",
    cn: "全国辣妹子食品公平贸易委员会"
  },
  'ハムソーセージ類公正取引協議会': {
    ja: "ハム・ソーセージ類公正取引協議会",
    en: "Ham and Sausage Fair Trade Council",
    cn: "火腿和香肠公平贸易委员会"
  },
  '社団法人菓子総合技術センター': {
    ja: "社団法人　菓子総合技術センター",
    en: "Confectionery Technology Center",
    cn: "糖果技术中心，公司。"
  },
  '社団法人日本塩工会': {
    ja: "社団法人　日本塩工会",
    en: "The Japan Salt Manufacturers Association, Inc.",
    cn: "日本盐场协会"
  },
  '全国はちみつ公正取引協議会': {
    ja: "全国はちみつ公正取引協議会",
    en: "National Honey Fair Trade Council",
    cn: "国家蜂蜜公平贸易委员会"
  },
  '全国味噌業公正取引協議会': {
    ja: "全国味噌業公正取引協議会",
    en: "National Miso Industry Fair Trade Council",
    cn: "全国味噌行业公平贸易委员会"
  },
  '全国うに食品公正取引協議会': {
    ja: "全国うに食品公正取引協議会",
    en: "National Sea Urchin Food Fair Trade Council",
    cn: "国家海胆食品公平贸易委员会"
  },
  '社団法人全国ローヤルゼリー公正取引協議会': {
    ja: "社団法人全国ローヤルゼリー公正取引協議会",
    en: "National Royal Jelly Fair Trade Council",
    cn: "全国蜂王浆公平贸易委员会"
  },
  '全日本コーヒー公正取引協議会': {
    ja: "全日本コーヒー公正取引協議会",
    en: "All Japan Coffee Fair Trade Council",
    cn: "全日本咖啡公平贸易委员会"
  },
  '食品のり公正取引協議会': {
    ja: "食品のり公正取引協議会",
    en: "Food Glue Fair Trade Council",
    cn: "食品胶水公平贸易委员会"
  },
  '森林管理協議会': {
    ja: "森林管理協議会",
    en: "Forest Stewardship Council",
    cn: "森林管理委员会"
  },
  '産業環境管理協会': {
    ja: "産業環境管理協会",
    en: "Japan Environmental Management Association for Industry",
    cn: "工业环境管理协会"
  },
  '海洋管理協議会': {
    ja: "海洋管理協議会",
    en: "Marine Stewardship Council",
    cn: "海洋管理委员会"
  },
  '全国ドレッシング類公正取引協議会': {
    ja: "全国ドレッシング類公正取引協議会",
    en: "National Fair Trade Council of Dressings",
    cn: "全国调味品公平贸易委员会"
  },
  '日本介護食品協議会': {
    ja: "日本介護食品協議会",
    en: "Japan Nursing Food Council",
    cn: "日本护理食品委员会"
  },
  '農林水産省': {
    ja: "農林水産省",
    en: "Ministry of Agriculture, Forestry and Fisheries",
    cn: "农业、林业和渔业部"
  },
};

