import {createMaxStringRule, requiredRule} from '@/utils/validation-rules';
import ObjectUtils from "@/utils/object-utils";
import { PartnerBase, ValidatorRules as PartnerValidatorRules } from './partner-entity-base';
import IFile, {IFileDefaultValue} from "@/contracts/i-file";

export class PartnerFactoryCertificationBase {
  public name!: string;
  public note!: string;
  public attachment!: IFile;

  constructor(init:Partial<PartnerFactoryCertificationBase> = {}) {
    // PartnerFactoryFormで一旦使ってる
    this.attachment = init.attachment || IFileDefaultValue;
  }

  public get isEmpty(): boolean {
    return !this.name && !this.note && !this.attachment.filename;
  }
}

export class PartnerFactoryCertificationEntity extends PartnerFactoryCertificationBase {
  public partnerFactoryId!:number;

  constructor(init:Partial<PartnerFactoryCertificationEntity> = {}) {
    super();
    ObjectUtils.assignLiteralFields(this, init);
    this.attachment = init.attachment || IFileDefaultValue;
  }

  public toSpec(): SpecPartnerFactoryCertificationEntity {
    return new SpecPartnerFactoryCertificationEntity(this);
  }
}

export class SpecPartnerFactoryCertificationEntity extends PartnerFactoryCertificationBase {
  public specPartnerFactoryId!:number;
  constructor(init:Partial<SpecPartnerFactoryCertificationEntity> = {}) {
    super();
    ObjectUtils.assignLiteralFields(this, init);
    this.attachment = init.attachment || IFileDefaultValue;
  }

}

export const Certifications = [
  'HACCP',
  'FDA HACCP',
  'Local HACCP',
  'SGS-HACCP',
  'Canada GAP',
  'FSSC 22000',
  'ISO/TS 22002-1',
  'ISO/TS 22002-2',
  'ISO/TS 22002-3',
  'ISO/TS 22002-4',
  'ISO 22000',
  'ISO 9001 / 2015',
  'ISO 9001-HACCP',
  'BRC',
  'Global Aquaculture Alliance',
  'Global GAP',
  'GRMS',
  'IFS',
  'Primus GFS',
  'SQF',
  'SQF1000',
  'SQF2000',
  'Government',
  'EU',
  'SFA',
  'SS590',
  'Halal',
  'Kosher',
  'BAP',
  'MSC',
  'ASC',
  'Organic',
];

export const ValidatorRules = {
  name: [requiredRule, createMaxStringRule()],
  note: [createMaxStringRule()],
};
