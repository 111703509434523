import {getTextByLang as t} from "@/bootstraps/locale";

export const NutritionUnitLang = {
  '個': t({
    ja: "1個",
    en: "1 Piece",
    cn: "1个"
  }),
  '食': t({
    ja: "1食",
    en: "1 Meal",
    cn: "1餐"
  }),
  '本': t({
    ja: "1本",
    en: "1 Stick",
    cn: "1本"
  }),
  '枚': t({
    ja: "1枚",
    en: "1 Sheet",
    cn: "1枚"
  }),
  '袋': t({
    ja: "1袋",
    en: "1 Bag",
    cn: "1 袋"
  }),
  '人前': t({
    ja: "1人前",
    en: "1 Serving",
    cn: "1人份"
  }),
  'パック': t({
    ja: "1パック",
    en: "1 Pack",
    cn: "1个包装" // TODO
  }),
  '切れ': t({
    ja: "1切れ",
    en: "1 Slice",
    cn: "1盘"
  }),
  '束': t({
    ja: "1束",
    en: "1 Bundle",
    cn: "1束"
  }),
  '粒': t({
    ja: "1粒",
    en: "1 Grain",
    cn: "1粒" // TODO
  }),
  '錠': t({
    ja: "1錠",
    en: "1 Tablet",
    cn: "1片" // TODO
  }),
  '巻': t({
    ja: "1巻",
    en: "1 Roll",
    cn: "1卷"
  }),
  '箱': t({
    ja: "1箱",
    en: "1 Box",
    cn: "1箱"
  }),
  '組': t({
    ja: "1組",
    en: "1 Set",
    cn: "1组"
  }),
  '膳': t({
    ja: "1膳",
    en: "1 Bowl",
    cn: "1顿"
  }),
  '丁': t({
    ja: "丁",
    en: '1 Piece(Tofu)',
    cn: '丁',
  }),
  '貫': t({
    ja: "1貫",
    en: "1 Piece(Sushi)",
    cn: "1貫"
  }),
  '包装': t({
    ja: "1包装",
    en: "1 Package",
    cn: "1包装"
  }),
};
