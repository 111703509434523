import {i18n} from "@/bootstraps/locale";
import CompanySpecCustomValueSettingEntity from "@/entities/company-spec-custom-value-setting-entity";
import {
  CustomValueEntityBase,
  ValidatorRules as CustomValueValidatorRules
} from "@/entities/interfaces/i-custom-value";

export default class SpecCustomValueEntity extends CustomValueEntityBase {
  public category!: SpecCustomValueCategory;
  constructor(init: Partial<SpecCustomValueEntity> = {}) {
    super(init);
  }
}

export const ValidatorRules = CustomValueValidatorRules;

export const SpecCustomValueCategory = {
  Basic: 1,
  Company: 2,
  Ingredient: 3,
  // Label: 4,
  Nutrition: 5,
  Package: 6,
  Manufacture: 7,
} as const;
export type SpecCustomValueCategory = typeof SpecCustomValueCategory[keyof typeof SpecCustomValueCategory]
export const SpecCustomValueCategoryDict = [
  { id: SpecCustomValueCategory.Basic, label: i18n.t('基本') },
  { id: SpecCustomValueCategory.Company, label: i18n.t('企業') },
  { id: SpecCustomValueCategory.Ingredient, label: i18n.t('原材料') },
  { id: SpecCustomValueCategory.Nutrition, label: i18n.t('栄養成分') },
  { id: SpecCustomValueCategory.Package, label: i18n.t('包材') },
  { id: SpecCustomValueCategory.Manufacture, label: i18n.t('製造') },
];
export type CompanySpecCustomValueSettingsPerCategory = {[key in SpecCustomValueCategory]: CompanySpecCustomValueSettingEntity[]};
export function splitPerCategory(original: CompanySpecCustomValueSettingEntity[]): CompanySpecCustomValueSettingsPerCategory {
  // 効率化できるけど、とりあえず
  return {
    [SpecCustomValueCategory.Basic]: original.filter(v => v.category === SpecCustomValueCategory.Basic),
    [SpecCustomValueCategory.Company]: original.filter(v => v.category === SpecCustomValueCategory.Company),
    [SpecCustomValueCategory.Ingredient]: original.filter(v => v.category === SpecCustomValueCategory.Ingredient),
    [SpecCustomValueCategory.Nutrition]: original.filter(v => v.category === SpecCustomValueCategory.Nutrition),
    [SpecCustomValueCategory.Package]: original.filter(v => v.category === SpecCustomValueCategory.Package),
    [SpecCustomValueCategory.Manufacture]: original.filter(v => v.category === SpecCustomValueCategory.Manufacture),
  };
}

