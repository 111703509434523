import {PartnerFactoryEntity} from "@/entities/specs/partner-factory-entity";
import {PartnerBase, ValidatorRules as PartnerValidatorRule} from './partner-entity-base';
import ObjectUtils from "@/utils/object-utils";
import {i18n} from "@/bootstraps/locale";
import IFile, {IFileDefaultValue} from "@/contracts/i-file";
import {createMaxStringRule, getTypeIntRule} from "@/utils/validation-rules";
import PartnerIntraCustomValueEntity from "@/entities/specs/partner-intra-custom-value-entity";

export class PartnerEntity extends PartnerBase {
  public type!:PartnerAllType;

  /** 管理情報 **/
  public intraCode:string|null = null;
  public establishmentYear:number|null = null;
  public annualSales:number|null = null;
  public annualSalesCurrency:string|null = '円';
  public employeesCount:number|null = null;
  public companyOverviewAttachment!: IFile;
  public intraMemo:string|null = null;

  public factories:PartnerFactoryEntity[] = [];
  public intraCustomValues: PartnerIntraCustomValueEntity[] = [];

  constructor(init:Partial<PartnerEntity> = {}) {
    super();
    ObjectUtils.assignLiteralFields(this, init);

    this.companyOverviewAttachment = init.companyOverviewAttachment || IFileDefaultValue;

    if (init.factories) {
      this.factories = init.factories.map(f => new PartnerFactoryEntity(f));
    }
    if (init.intraCustomValues) {
      this.intraCustomValues = init.intraCustomValues.map(f => new PartnerIntraCustomValueEntity(f));
    }
  }
}


export class SpecPartnerEntity extends PartnerBase {
  public referencePartnerId!:number; // spec_partnerのみ

  constructor(init:Partial<SpecPartnerEntity> = {}) {
    super();
    ObjectUtils.assignLiteralFields(this, init);
  }
}

export const MakerType = {
  Manufacturer : 2,
  Processor: 3,
  Importer: 4,
} as const;
export type MakerType = typeof MakerType[keyof typeof MakerType];
export const MakerTypeDict = {
  [MakerType.Manufacturer]: i18n.t('製造者'),
  [MakerType.Processor]: i18n.t('加工者'),
  [MakerType.Importer]: i18n.t('輸入者'),
} as {[key in MakerType]: string};

export const PartnerType = {
  Seller: 1,
  ...MakerType
} as const;
export type PartnerType = typeof PartnerType[keyof typeof PartnerType];
export const PartnerTypeDict = {
  [PartnerType.Seller]: i18n.t('販売者'),
  ...MakerTypeDict,
} as {[key in PartnerType]: string};

export const FactoryType = {
  FactoryManufacturer : 5,
  FactoryProcessor: 6,
  Importer: MakerType.Importer,
} as const;
export type FactoryType = typeof FactoryType[keyof typeof FactoryType];
export const FactoryTypeDict = {
  [FactoryType.FactoryManufacturer]: i18n.t('製造所'),
  [FactoryType.FactoryProcessor]: i18n.t('加工所'),
  [FactoryType.Importer]: i18n.t('輸入者'),
} as {[key in FactoryType]: string};

export const PartnerAllType = {
  ...PartnerType,
  ...FactoryType
} as const;
export type PartnerAllType = PartnerType | FactoryType;
export const PartnerAllTypeDict  = {
  ...PartnerTypeDict,
  ...FactoryTypeDict
};

export const ValidatorRules:any = Object.assign({}, PartnerValidatorRule, {
  intraCode: [ createMaxStringRule() ],
  establishmentYear: [ getTypeIntRule() ],
  annualSales: [ getTypeIntRule() ],
  employeesCount: [ getTypeIntRule() ],
  companyOverviewAttachment: [],
});
