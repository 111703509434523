import {getTextByLang as t} from "@/bootstraps/locale";

export const GmoCropsLang = {
  '大豆': t({
    ja: "大豆",
    en: "Soybean",
    cn: "大豆"
  }),
  'とうもろこし': t({
    ja: "とうもろこし",
    en: "Corn",
    cn: "玉米"
  }),
  'ばれいしょ': t({
    ja: "ばれいしょ",
    en: "Potato",
    cn: "马铃薯"
  }),
  'なたね': t({
    ja: "なたね",
    en: "Rapeseed",
    cn: "油菜籽"
  }),
  '綿実': t({
    ja: "綿実",
    en: "Cottonseed",
    cn: "棉籽"
  }),
  'アルファルファ': t({
    ja: "アルファルファ",
    en: "Alfalfa",
    cn: "紫花苜蓿"
  }),
  'てん菜': t({
    ja: "てん菜",
    en: "Sugar beet",
    cn: "甜菜"
  }),
  'パパイヤ': t({
    ja: "パパイヤ",
    en: "Papaya",
    cn: "番木瓜"
  }),
  'からしな': t({
    ja: "からしな",
    en: "Leaf mustard",
    cn: "芥菜"
  }),
};

export const GmoTypesLang = {
  '遺伝子組換え': t({
    ja: "遺伝子組換え",
    en: "GMO",
    cn: "转基因"
  }),
  '分別生産流通管理済み（混入率：5％以下）': t({
    ja: "分別生産流通管理済み（混入率：5％以下）",
    en: "IP Handling (Mix Rate: 5% or less)",
    cn: "完成生产流通分开管理（混入率：5％以下）"
  }),
  '分別生産流通管理済み': t({
    ja: "分別生産流通管理済み",
    en: "Identity Preserved Handling",
    cn: "完成生产流通分开管理"
  }),
  '遺伝子組換えでない（混入率：0％）': t({
    ja: "遺伝子組換えでない（混入率：0％）",
    en: "Non-GMO（Mix Rate：0％）",
    cn: "非转基因（混入率：0％）"
  }),
  '遺伝子組換えでない': t({
    ja: "遺伝子組換えでない",
    en: "Non-GMO",
    cn: "非转基因"
  }),
  '遺伝子組換えでない（2023年度改正前基準）': t({
    ja: "遺伝子組換えでない（2023年度改正前基準）",
    en: "Non-GMO（Standards before 2023 revision）",
    cn: '不是转基因的(2023年修订前的标准)',
  }),
  '（廃止予定）遺伝子組換えでない（2023年度改正前基準）': t({
    ja: "（廃止予定）遺伝子組換えでない（2023年度改正前基準）",
    en: "(Deprecated) Non-GMO（Standards before 2023 revision）",
    cn: '(将被废除) 不是转基因的(2023年修订前的标准)',
  }),
  '遺伝子組換えのものを分別': t({
    ja: "遺伝子組換えのものを分別",
    en: "classified GMO",
    cn: "区别转基因食品"
  }),
  '遺伝子組換え不分別': t({
    ja: "遺伝子組換え不分別",
    en: "Non-classified GMO",
    cn: "不区别转基因"
  }),
  '高リシン遺伝子組換え': t({
    ja: "高リシン遺伝子組換え",
    en: "high-lysine GMO",
    cn: "高赖氨酸转基因"
  }),
  '高リシン遺伝子組換えのものを混合': t({
    ja: "高リシン遺伝子組換えのものを混合",
    en: "contain high-lysine GMO",
    cn: "混有高赖氨酸转基因物质"
  }),
  'ステアリドン酸産生遺伝子組換え': t({
    ja: "ステアリドン酸産生遺伝子組換え",
    en: "Stearidonic acid GMO",
    cn: "产生硬脂四烯酸的转基因"
  }),
  'ステアリドン酸産生遺伝子組換えのものを混合': t({
    ja: "ステアリドン酸産生遺伝子組換えのものを混合",
    en: "contain Stearidonic acid GMO",
    cn: "混有产生硬脂四烯酸的转基因物质"
  }),
};

