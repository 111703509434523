import {createMaxStringRule} from '@/utils/validation-rules';
import ObjectUtils from "@/utils/object-utils";
import { PartnerBase, ValidatorRules as PartnerValidatorRules } from './partner-entity-base';
import {
  PartnerFactoryCertificationBase,
  PartnerFactoryCertificationEntity,
  SpecPartnerFactoryCertificationEntity
} from "@/entities/specs/partner-factory-certification-entity";
import IFile, {IFileDefaultValue} from "@/contracts/i-file";
import PartnerFactoryIntraCustomValueEntity from "@/entities/specs/partner-factory-intra-custom-value-entity";

export abstract class PartnerFactoryBase extends PartnerBase {
  public factoryUniqueCode:string|null = null;
  public leadTimeDays:number|null = null;
  public leadTimeHours:number|null = null;
  public leadTimeMinutes:number|null = null;
  public caseQtyPerLot:number|null = null;
  public productionLotQtyPerDay:number|null = null;
  public minOrderableLotQty:number|null = null;
  public certifications: PartnerFactoryCertificationBase[] = [];

  /** 管理情報 **/
  public intraCode:string|null = null;
  public employeesCount:number|null = null;
  public factoryLayoutAttachment!: IFile;
  public factoryOrganizationAttachment!: IFile;
}

export class PartnerFactoryEntity extends PartnerFactoryBase {
  public partnerId!:number;
  public certifications: PartnerFactoryCertificationEntity[] = [];

  public intraCustomValues: PartnerFactoryIntraCustomValueEntity[] = [];
  public intraMemo:string|null = null;

  constructor(init:Partial<PartnerFactoryEntity> = {}) {
    super();
    ObjectUtils.assignLiteralFields(this, init);

    this.factoryLayoutAttachment = init.factoryLayoutAttachment || IFileDefaultValue;
    this.factoryOrganizationAttachment = init.factoryOrganizationAttachment || IFileDefaultValue;

    if (init.certifications) {
      this.certifications = init.certifications.map((c) => new PartnerFactoryCertificationEntity(c))
    }

    if (init.intraCustomValues) {
      this.intraCustomValues = init.intraCustomValues.map(f => new PartnerFactoryIntraCustomValueEntity(f));
    }
  }
}

export class SpecPartnerFactoryEntity extends PartnerFactoryBase {
  public specId!:number;
  public referencePartnerFactoryId!:number;
  public certifications: SpecPartnerFactoryCertificationEntity[] = [];
  constructor(init:Partial<SpecPartnerFactoryEntity> = {}) {
    super();
    ObjectUtils.assignLiteralFields(this, init);

    if (init.certifications) {
      this.certifications = init.certifications.map((c) => new SpecPartnerFactoryCertificationEntity(c))
    }
  }

  public import(data: PartnerFactoryEntity): this {
    super.import(data);
    this.certifications = data.certifications.map(c => c.toSpec());
    return this;
  }
}

export const ValidatorRules = Object.assign({}, PartnerValidatorRules, {
  factoryUniqueCode: [createMaxStringRule()],
});
