import cloneDeep from "lodash/cloneDeep";

let rowKeyCounter = 0;

export default class RowKey {
  public static getRowKey() {
    return ++rowKeyCounter;
  }
  public static clone<T extends HasRowKey>(org:T, recursiveProp?: string): T{
    const newOne = cloneDeep(org);
    newOne.rowKey = RowKey.getRowKey();
    if (recursiveProp) {
      const setRowKeyRecursive = (obj: any) => {
        if (!obj[recursiveProp] || !Array.isArray(obj[recursiveProp])) return;

        obj[recursiveProp].forEach((c: any) => {
          c.rowKey = RowKey.getRowKey();
          setRowKeyRecursive(c);
        });
      }
      setRowKeyRecursive(newOne);
    }
    return newOne;
  }
}
export interface HasRowKey {
  rowKey:number;
}

