import SpecIngredientEntity from "@/entities/specs/spec-ingredient-entity";
import SpecEntity, {SpecBaseEntity} from "@/entities/specs/spec-entity";
import sum from "lodash/sum";

export default class SpecIngredientCollection {
  constructor(public spec: SpecBaseEntity, public ingredients: SpecIngredientEntity[]) {
  }

  public getAmount(ing: SpecIngredientEntity):number {
    if (this.spec.shouldSumChildrenAmount(ing)) {
      return sum(ing.children.map(c => this.getAmount(c)));
    } else {
      return ing.amount || 0;
    }
  }
  public static getAmountSum(ing: SpecIngredientEntity):number {
    return ing.hasAnyChildren ?
      sum(ing.children.map(c => SpecIngredientCollection.getAmountSum(c))) :
      ing.amount || 0;
  }

  public getChildDepth(haystack:SpecIngredientEntity): number {
    const res = this.findChildDepth(haystack, this.ingredients, 0);
    if (res === null) {
      console.error(haystack);
      console.error(this.ingredients);
      throw new Error('コレクションに存在しないキーが渡されています(getChildDepth)');
    }
    return res;
  }
  private findChildDepth(haystack:SpecIngredientEntity, ingredients: SpecIngredientEntity[] = this.ingredients, walk = 0): number | null {
    for(const i of ingredients) {
      if(i.rowKey === haystack.rowKey) return walk;
      const foundInChild = this.findChildDepth(haystack, i.children, walk + 1);
      if (foundInChild !== null) return foundInChild;
    }
    return null;
  }
  public getRootAmountSum(): number {
    if (this.spec.isAmountRatioInTotal) {
      return sum(this.ingredients.filter(i => !i.hasAnyChildren).map(i => Number(i.amount || 0)));
    } else {
      return sum(this.ingredients.filter(i => this.getChildDepth(i) === 0).map(i => Number(i.amount || 0)));
    }
  }

  public getLevel(haystack:SpecIngredientEntity, glue = '-'): string {
    const level = this.findLevel(haystack);
    if (level === null) throw new Error('コレクションに存在しないキーが渡されています(getLevel)');
    return level.join(glue);
  }
  public findLevel(haystack:SpecIngredientEntity, ingredients: SpecIngredientEntity[] = this.ingredients, walk: number[] = []): number[] | null {
    for(let i = 0; i < ingredients.length; i++) {
      const ing = ingredients[i];
      const idx = i + 1;
      if(ing.rowKey === haystack.rowKey) return walk.concat(idx);
      const foundInChild = this.findLevel(haystack, ing.children, walk.concat(idx));
      if (foundInChild !== null) return foundInChild;
    }
    return null;
  }
}
