import ProductDisplaySetting, {IMadeInAreaOption} from "@/entities/product-display-setting";
import ProductDisplayService, {IMergedNormalizedItem} from "@/services/product-display-service";
import IngredientEntity from "@/entities/ingredient-entity";

import groupBy from 'lodash/groupBy';
import sumBy from "lodash/sumBy";
import uniqBy from "lodash/uniqBy";
import IngredientMadeInAreaEntity from "@/entities/ingredient-made-in-area-entity";
import {CompanySettingEntity} from "@/entities/company-setting-entity";
import {IRecipeComponent} from "@/entities/interfaces/i-recipe-component";

export default class ProductDisplayServiceMadeInArea {
  private productDisplayService!:ProductDisplayService;

  public constructor(
    private readonly product: IRecipeComponent,
    private readonly setting: ProductDisplaySetting,
    public readonly companySetting: CompanySettingEntity,
  ) {
    this.productDisplayService = new ProductDisplayService(product, setting, companySetting);
  }

  // エリアを表示する対象の原材料取得 (原材料(名)ごとにまとめる)（重量が同率1位の場合は複数）
  public getTargetItems(): IMergedNormalizedItem[] {
    const items = ProductDisplayService.normalize(this.product, this.setting);
    if (!items.length) return [];

    const mergedAndSorted = this.productDisplayService.mergeAndSortNormalizedItem(
      this.product,
      items
    );
    const itemsFiltered = ProductDisplayService.excludeFromIngredientNamesRecursive(mergedAndSorted, this.setting.isWaterOmitted);
    if (itemsFiltered.length === 0) return [];
    const maxAmount = itemsFiltered[0].concentrationAmountRatioInRootProduct;

    return ProductDisplayServiceMadeInArea.getMaxAmountItems(itemsFiltered, maxAmount);
  }

  public static getMaxAmountItems(items: IMergedNormalizedItem[], maxAmount:number) {
    const targetItems = items.filter(i => i.concentrationAmountRatioInRootProduct === maxAmount);
    return targetItems.flatMap(i => {
      if(i.isPreproduct) {
        return ProductDisplayServiceMadeInArea.getMaxAmountItems(i.items, maxAmount);
      }
      return i;
    });
  }

  public static getMadeInNameList(ingredients:IngredientEntity[], setting: IMadeInAreaOption): string[] {
    if(ingredients.flatMap(targetIngredient => targetIngredient.ingredientMadeInAreas).length === 0) return [];

    // 大括りルールの適用
    const mergedItems:{name:string, isDomestic:boolean, amount:number}[] =
      ingredients!.flatMap((targetIngredient:IngredientEntity) => {
        const items:{name:string, isDomestic:boolean, amount:number}[] = [];

        // 海外大括り
        let ingredientMadeInAreasForeign = targetIngredient.ingredientMadeInAreas.filter(im => !im.isDomestic);
        if (setting.isMadeInAreasManyForeignSummarized && ingredientMadeInAreasForeign.length >= 3) {
          items.push({
            name: targetIngredient.isAsProcessed ? '外国製造' : '輸入', isDomestic: false, amount: 0 /*TODO*/
          });
        } else {
          ingredientMadeInAreasForeign.forEach(imi => {
            items.push({name: imi.getLabelDisplayName(targetIngredient.isAsProcessed)!, isDomestic: false, amount: 0 /*TODO*/});
          });
        }

        // 国内大括り
        let ingredientMadeInAreasDomestic = targetIngredient.ingredientMadeInAreas.filter(im => !!im.isDomestic);
        if (setting.isMadeInAreasDomesticSummarized && ingredientMadeInAreasDomestic.length) {
          items.push({
            name: targetIngredient.isAsProcessed ? '国内製造' : '国産', isDomestic: true, amount: 0 /*TODO*/
          });
        } else {
          ingredientMadeInAreasDomestic.forEach(imi => {
            items.push({name: imi.getLabelDisplayName(targetIngredient.isAsProcessed)!, isDomestic: true, amount: 0 /*TODO*/ });
          });
        }

        return items;
      });

    const grouped = groupBy(mergedItems, item => item.name);
    const summed = Object.values(grouped).map((g) => {
      return {
        name: g[0].name,
        isDomestic: g[0].isDomestic,
        amount: sumBy(g, i => i.amount)
      };
    });
    const sorted = summed.sort((a, b) => { return b.amount - a.amount; });
    const names = sorted.map(s => s.name);

    // 3カ国目以降を「豚肉（アメリカ、カナダ、その他）」のように「その他」と表示する
    // ※都道府県が複数あっても、国内で1カ国とカウントする
    const countOfCountry = sorted.filter(s => !s.isDomestic).length + (sorted.some(s => s.isDomestic) ? 1 : 0);
    if (setting.isManyMadeInAreasOmitted && countOfCountry > 2) {
      names.splice(2);
      names.push('その他');
    }

    return names;
  }

  public getMadeInNames(appendOrPrependIngredientName:'append'|'prepend'|false = false): string {
    const targetItem = this.getTargetItems();

    const concatName = (areaName:string, ingredientName) => {
      if (appendOrPrependIngredientName === 'append') {
        return areaName + `（${ingredientName}）`;
      } else if (appendOrPrependIngredientName === 'prepend') {
        return ingredientName + `（${areaName}）`;
      } else {
        return areaName;
      }
    };

    const setting = this.setting;

    if (targetItem.length === 0) {
      return '';
    } else if (targetItem.length === 1) {
      // 「アメリカ産、カナダ産（豚肉）」
      // 「アメリカ産又はカナダ産（豚肉）」
      const item = targetItem[0];
      const areaNames = ProductDisplayServiceMadeInArea.getMadeInNameList(item.ingredients, setting);
      if (areaNames.length === 0) return '';

      const joinedAreaNames = ProductDisplayServiceMadeInArea.joinAreas(areaNames, setting);

      return concatName(joinedAreaNames, item.displayName);
    } else {
      // アメリカ産（豚肉）、カナダ産（豚肉）、 アメリカ産（小麦）、北海道産（小麦）、秋田産（小麦）
      // x アメリカ産（豚肉）又はカナダ産（豚肉）、 アメリカ産（小麦）又は北海道産（小麦）又は秋田産（小麦）
      // => アメリカ産又はカナダ産（豚肉）、 アメリカ産又は北海道産又は秋田産（小麦）
      return targetItem.map(item => {
        const areaNames = ProductDisplayServiceMadeInArea.getMadeInNameList(item.ingredients, setting);
        if (areaNames.length === 0) return '';

        const joinedAreaNames = ProductDisplayServiceMadeInArea.joinAreas(areaNames, setting);

        if (joinedAreaNames.includes("又は")) {
          return concatName(joinedAreaNames, item.displayName);
        } else {
          return areaNames.map(area => {
            return concatName(area, item.displayName);
          }).join("、");
        }
      }).filter(a => !!a).join("、");
    }
  }

  // 又は表示
  private static getAreaSeparator(areaNames: string[], setting: IMadeInAreaOption): string {
    return (setting.isMadeInAreasRatioVariable && areaNames.length > 1) ? '又は' : '、';
  }

  public static joinAreas(names: string[], setting: IMadeInAreaOption) {
    return names.join(ProductDisplayServiceMadeInArea.getAreaSeparator(names, setting));
  }

  // region  Visibility Setting
  public getTargetMadeInAreasUniqStatic(): IngredientMadeInAreaEntity[] {
    const res = this.getTargetItems();
    if (res.length === 0) return [];

    const allMadeInAreas = res.flatMap(r => r.ingredients!.flatMap(i => i.ingredientMadeInAreas));
    return uniqBy(allMadeInAreas, (im:IngredientMadeInAreaEntity) => im.getLabelDisplayName(false))
  }
  private getTargetMadeInAreasUniq(): IngredientMadeInAreaEntity[] {
    return this.getTargetMadeInAreasUniqStatic();
  }
  public get hasAnyMadeInArea() {
    return this.getTargetMadeInAreasUniq().length >= 1;
  }
  // 3カ国以上あるか
  public get showManyMadeInAreasOmitted() {
    const countOfCountry = this.getTargetMadeInAreasUniq().filter(imi => !imi.isDomestic).length +
      (this.getTargetMadeInAreasUniq().some(imi => !!imi.isDomestic) ? 1 : 0);

    return countOfCountry >= 3;
  }
  // エリアが2つ以上あるか
  public get showMadeInAreasRatioVariable() {
    return this.getTargetMadeInAreasUniq().length >= 2;
  }
  // 海外が3カ国以上あるか
  public get showMadeInAreasManyForeignSummarized() {
    return this.getTargetMadeInAreasUniq().filter(imi => !imi.isDomestic).length >= 3;
  }
  // 国内が含まれるか
  public get showMadeInAreasDomesticSummarized() {
    return this.getTargetMadeInAreasUniq().some(imi => !!imi.isDomestic);
  }
  // endregion
}
