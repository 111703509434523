import {NutritionValue} from "@/entities/nutrition-value";
import {NutritionUnitLang as lang} from "@/lang/enum/nutrition-unit-lang";

export default class NutritionEntity {
  public id!:number;
  public name!:string;
  public synonym!:string;

  public calorie!:NutritionValue;
  public lipid!:NutritionValue;
  public protein!:NutritionValue;
  public carb!:NutritionValue;
  public water!:NutritionValue;
  public ash!:NutritionValue;
  public salt!:NutritionValue;

  public saturatedFattyAcids!:NutritionValue;
  public monoUnsaturatedFattyAcids!:NutritionValue;
  public polyUnsaturatedFattyAcids!:NutritionValue;
  public omega3!:NutritionValue;
  public omega6!:NutritionValue;
  public cholesterol!:NutritionValue;
  public transFattyAcids!:NutritionValue;

  public sugar!:NutritionValue;
  public fiber!:NutritionValue;
  public solubleFiber!:NutritionValue;
  public insolubleFiber!:NutritionValue;

  public zinc!:NutritionValue;
  public potassium!:NutritionValue;
  public calcium!:NutritionValue;
  public chromium!:NutritionValue;
  public selenium!:NutritionValue;
  public iron!:NutritionValue;
  public copper!:NutritionValue;
  public magnesium!:NutritionValue;
  public natrium!:NutritionValue;
  public manganese!:NutritionValue;
  public molybdenum!:NutritionValue;
  public iodine!:NutritionValue;
  public phosphorous!:NutritionValue;

  public niacin!:NutritionValue;
  public pantothenicAcid!:NutritionValue;
  public biotin!:NutritionValue;
  public vitaminB1!:NutritionValue;
  public vitaminB2!:NutritionValue;
  public vitaminB6!:NutritionValue;
  public vitaminB12!:NutritionValue;
  public vitaminC!:NutritionValue;
  public vitaminD!:NutritionValue;
  public vitaminK!:NutritionValue;
  public folicAcid!:NutritionValue;

  public vitaminA!:NutritionValue;
  public retinol!:NutritionValue;
  public alphaCarotene!:NutritionValue;
  public betaCarotene!:NutritionValue;
  public betaCryptoxanthin!:NutritionValue;
  public betaCaroteneEquivalent!:NutritionValue;
  public alphaTocopherol!:NutritionValue;
  public betaTocopherol!:NutritionValue;
  public gammaTocopherol!:NutritionValue;
  public deltaTocopherol!:NutritionValue;

  constructor(init:NutritionEntity) {
    this.id = init.id!;
    this.name = init.name;
    this.synonym = init.synonym;

    Object.keys(init).filter(k => !['id','name','synonym'].includes(k)).forEach(k => {
      this[k] = new NutritionValue(init[k], k);
    });
  }
}

export const BasicNutritionProps = [
  'calorie',
  'protein',
  'lipid',
  'carb',
  'natrium',
  'salt',
];

export const NutritionUnitDict = {
  calorie: "kcal",
  salt: 'g',
  protein: 'g',
  lipid: 'g',
  saturatedFattyAcids: 'g',
  sugar: 'g',
  monoUnsaturatedFattyAcids: 'g',
  polyUnsaturatedFattyAcids: 'g',
  omega3: 'g',
  omega6: 'g',
  transFattyAcids: 'g',
  cholesterol: 'mg',
  carb: 'g',
  saccharides: 'g',
  fiber: 'g',
  solubleFiber: 'g',
  insolubleFiber: 'g',

  water: 'g',
  ash: 'g',
  alcohol: 'g',

  vitaminA: 'μg',
  retinol: 'μg',
  alphaCarotene: 'μg',
  betaCarotene: 'μg',
  betaCryptoxanthin: 'μg',
  betaCaroteneEquivalent: 'μg',
  vitaminB1: 'mg',
  vitaminB2: 'mg',
  vitaminB6: 'mg',
  vitaminB12: 'μg',
  niacin: 'mg',
  niacinEquivalent: 'mg',
  folicAcid: 'μg',
  pantothenicAcid: 'mg',
  biotin: 'μg',
  vitaminC: 'mg',
  vitaminD: 'μg',
  vitaminK: 'μg',
  alphaTocopherol: 'mg',
  betaTocopherol: 'mg',
  gammaTocopherol: 'mg',
  deltaTocopherol: 'mg',

  potassium: 'mg',
  calcium: 'mg',
  chromium: 'μg',
  selenium: 'μg',
  iron: 'mg',
  copper: 'mg',
  magnesium: 'mg',
  manganese: 'mg',
  molybdenum: 'μg',
  iodine: 'μg',
  phosphorous: 'mg',
  zinc: 'mg',
  natrium: 'mg',
};

export const BasicNutritionList = [
  {key: 'calorie', label: '熱量', unit: NutritionUnitDict.calorie},
  {key: 'protein', label: 'たんぱく質', unit: NutritionUnitDict.protein},
  {key: 'lipid', label: '脂質', unit: NutritionUnitDict.lipid},
  {key: 'carb', label: '炭水化物', unit: NutritionUnitDict.carb},
  {key: 'saltValue', label: '食塩相当量', unit: NutritionUnitDict.salt},
];

export const DetailList = [
  { prop: 'calorie', label: '熱量', unit: NutritionUnitDict.calorie },
  { prop: 'water', label: '水分', unit: NutritionUnitDict.water },
  { prop: 'ash', label: '灰分', unit: NutritionUnitDict.ash },
  { prop: 'protein', label: 'たんぱく質', unit: NutritionUnitDict.protein },
  { prop: 'lipid', label: '脂質', unit: NutritionUnitDict.lipid },
  { level: 1, prop: 'saturatedFattyAcids', label: '飽和脂肪酸', unit: NutritionUnitDict.saturatedFattyAcids },
  { level: 1, prop: 'monoUnsaturatedFattyAcids', label: '一価不飽和脂肪酸', unit: NutritionUnitDict.monoUnsaturatedFattyAcids },
  { level: 1, prop: 'polyUnsaturatedFattyAcids', label: '多価不飽和脂肪酸', unit: NutritionUnitDict.polyUnsaturatedFattyAcids },
  { level: 2, prop: 'omega3', label: 'n-3系脂肪酸', unit: NutritionUnitDict.omega3 },
  { level: 2, prop: 'omega6', label: 'n-6系脂肪酸', unit: NutritionUnitDict.omega6 },
  { level: 1, prop: 'transFattyAcids', label: 'トランス脂肪酸', unit: NutritionUnitDict.transFattyAcids },
  { prop: 'cholesterol', label: 'コレステロール', unit: NutritionUnitDict.cholesterol },
  { prop: 'carb', label: '炭水化物', unit: NutritionUnitDict.carb },
  { level: 1, prop: 'sugar', label: '糖質', unit: NutritionUnitDict.sugar },
  { level: 2, prop: 'saccharides', label: '糖類', unit: NutritionUnitDict.saccharides },
  { level: 1, prop: 'fiber', label: '食物繊維', unit: NutritionUnitDict.fiber },
  { level: 2, prop: 'solubleFiber', label: '水溶性食物繊維', unit: NutritionUnitDict.solubleFiber },
  { level: 2, prop: 'insolubleFiber', label: '不溶性食物繊維', unit: NutritionUnitDict.insolubleFiber },
  { prop: 'salt', label: '食塩相当量', unit: NutritionUnitDict.salt },
];

export const VitaminList: Readonly<{
  level?: number;
  prop: string;
  label: string;
  unit: string;
  compareProp?: string;
}[]> = [
  { prop: 'vitaminA', label: 'ビタミンA', unit: NutritionUnitDict.vitaminA },
  { level: 1, prop: 'retinol', label: 'レチノール', unit: NutritionUnitDict.retinol },
  { level: 1, prop: 'alphaCarotene', label: 'αカロテン', unit: NutritionUnitDict.alphaCarotene },
  { level: 1, prop: 'betaCarotene', label: 'βカロテン', unit: NutritionUnitDict.betaCarotene },
  { level: 1, prop: 'betaCryptoxanthin', label: 'クリプトキサンチン', unit: NutritionUnitDict.betaCryptoxanthin },
  { level: 1, prop: 'betaCaroteneEquivalent', label: 'β-カロテン当量', unit: NutritionUnitDict.betaCaroteneEquivalent },
  { prop: 'vitaminB1', label: 'ビタミンB1', unit: NutritionUnitDict.vitaminB1 },
  { prop: 'vitaminB2', label: 'ビタミンB2', unit: NutritionUnitDict.vitaminB2 },
  { prop: 'vitaminB6', label: 'ビタミンB6', unit: NutritionUnitDict.vitaminB6 },
  { prop: 'vitaminB12', label: 'ビタミンB12', unit: NutritionUnitDict.vitaminB12 },
  { prop: 'niacin', label: 'ナイアシン', unit: NutritionUnitDict.niacin },
  { prop: 'folicAcid', label: '葉酸', unit: NutritionUnitDict.folicAcid },
  { prop: 'pantothenicAcid', label: 'パントテン酸', unit: NutritionUnitDict.pantothenicAcid },
  { prop: 'biotin', label: 'ビオチン', unit: NutritionUnitDict.biotin },
  { prop: 'vitaminC', label: 'ビタミンC', unit: NutritionUnitDict.vitaminC },
  { prop: 'vitaminD', label: 'ビタミンD', unit: NutritionUnitDict.vitaminD },
  { prop: 'vitaminK', label: 'ビタミンK', unit: NutritionUnitDict.vitaminK },
  { prop: 'alphaTocopherol', label: 'ビタミンE', unit: NutritionUnitDict.alphaTocopherol, compareProp: 'vitaminE' },
  { level: 1, prop: 'alphaTocopherol', label: 'αトコフェロール', unit: NutritionUnitDict.alphaTocopherol },
  { level: 1, prop: 'betaTocopherol', label: 'βトコフェロール', unit: NutritionUnitDict.betaTocopherol },
  { level: 1, prop: 'gammaTocopherol', label: 'γトコフェロール', unit: NutritionUnitDict.gammaTocopherol },
  { level: 1, prop: 'deltaTocopherol', label: 'δトコフェロール', unit: NutritionUnitDict.deltaTocopherol },
] as const;

export const MineralList = [
  { prop: 'zinc', label: '亜鉛', unit: NutritionUnitDict.zinc },
  { prop: 'potassium', label: 'カリウム', unit: NutritionUnitDict.potassium },
  { prop: 'calcium', label: 'カルシウム', unit: NutritionUnitDict.calcium },
  { prop: 'chromium', label: 'クロム', unit: NutritionUnitDict.chromium },
  { prop: 'selenium', label: 'セレン', unit: NutritionUnitDict.selenium },
  { prop: 'iron', label: '鉄', unit: NutritionUnitDict.iron },
  { prop: 'copper', label: '銅', unit: NutritionUnitDict.copper },
  { prop: 'magnesium', label: 'マグネシウム', unit: NutritionUnitDict.magnesium },
  { prop: 'natrium', label: 'ナトリウム', unit: NutritionUnitDict.natrium },
  { prop: 'manganese', label: 'マンガン', unit: NutritionUnitDict.manganese },
  { prop: 'molybdenum', label: 'モリブデン', unit: NutritionUnitDict.molybdenum },
  { prop: 'iodine', label: 'ヨウ素', unit: NutritionUnitDict.iodine },
  { prop: 'phosphorous', label: 'リン', unit: NutritionUnitDict.phosphorous },
];

export const NutritionUnits = [
  lang.個,
  lang.食,
  lang.本,
  lang.枚,
  lang.袋,
  lang.人前,
  lang.パック,
  lang.切れ,
  lang.束,
  lang.粒,
  lang.錠,
  lang.巻,
  lang.箱,
  lang.組,
  lang.膳,
  lang.丁,
  lang.貫,
  lang.包装,
];
