export const SpecPackagePartsNameLang = {
  '個包装': {
    ja: "個包装",
    en: "Individual packaging",
    cn: "个别包装"
  },
  '内装': {
    ja: "内装",
    en: "Inner packaging",
    cn: "内包装"
  },
  '内装フィルム': {
    ja: "内装フィルム",
    en: "Interior film",
    cn: "内包装贴膜"
  },
  '内箱': {
    ja: "内箱",
    en: "Inner box",
    cn: "内箱"
  },
  'トレー': {
    ja: "トレー",
    en: "Tray",
    cn: "托盘"
  },
  'フードパック': {
    ja: "フードパック",
    en: "Food pack",
    cn: "食品包装"
  },
  '外箱': {
    ja: "外箱",
    en: "Outer Box",
    cn: "外箱"
  },
  '外袋': {
    ja: "外袋",
    en: "Outer Bag",
    cn: "外包装袋"
  },
  '外装フィルム': {
    ja: "外装フィルム",
    en: "Outer film",
    cn: "外包装袋贴膜"
  },
  'シュリンクフィルム': {
    ja: "シュリンクフィルム",
    en: "Shrink film",
    cn: "收缩膜"
  },
  'ラベル': {
    ja: "ラベル",
    en: "Labels",
    cn: "标签"
  },
  'テープ': {
    ja: "テープ",
    en: "Tape",
    cn: "胶带"
  },
  'シール': {
    ja: "シール",
    en: "Seals",
    cn: "贴纸"
  },
  '留め具': {
    ja: "留め具",
    en: "Clamps",
    cn: "夹子"
  },
  'バンド': {
    ja: "バンド",
    en: "Bands",
    cn: "带子"
  },
  'フォーク': {
    ja: "フォーク",
    en: "Forks",
    cn: "叉子"
  },
  'スプーン': {
    ja: "スプーン",
    en: "Spoon",
    cn: "勺子"
  },
  'ボトル': {
    ja: "ボトル",
    en: "Bottle",
    cn: "瓶子"
  },
  '缶': {
    ja: "缶",
    en: "Cans",
    cn: "罐头"
  },
  'カップ': {
    ja: "カップ",
    en: "Cup",
    cn: "杯子"
  },
  'フタ': {
    ja: "フタ",
    en: "Lid",
    cn: "盖子"
  },
  'キャップ': {
    ja: "キャップ",
    en: "Cap",
    cn: "笔帽"
  },
  'ストロー': {
    ja: "ストロー",
    en: "Straws",
    cn: "吸管"
  },
  '中栓': {
    ja: "中栓",
    en: "Inner Cap",
    cn: "塞子"
  },
};
