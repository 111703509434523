import {
  createDecimalRule, typeNumberOrEmptyRule, createMaxStringRule
} from '@/utils/validation-rules';

import ObjectUtils from "@/utils/object-utils";
import {SpecPackagePartsNameLang as lang} from "@/lang/enum/spec-package-parts-name-lang";
import {getTextByLang as trans} from "@/bootstraps/locale";

export default class SpecPackageMaterialEntity {
  public id!:number;
  public partsName:string|null = null;
  public materialName:string|null = null;
  public weight:number|null = null;
  public sealingMethod:string|null = null;
  public note:string|null = null;

  constructor(init:Partial<SpecPackageMaterialEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);
  }
}

export const ValidatorRules = {
  partsName: [createMaxStringRule()],
  materialName: [createMaxStringRule()],
  weight: [typeNumberOrEmptyRule, createDecimalRule(11,3)],
  sealingMethod: [createMaxStringRule()],
  note: [createMaxStringRule()],
};

export const PartsNames = [
  trans(lang.個包装),
  trans(lang.内装),
  trans(lang.内装フィルム),
  trans(lang.内箱),
  trans(lang.トレー),
  trans(lang.フードパック),
  trans(lang.外箱),
  trans(lang.外袋),
  trans(lang.外装フィルム),
  trans(lang.シュリンクフィルム),
  trans(lang.ラベル),
  trans(lang.テープ),
  trans(lang.シール),
  trans(lang.留め具),
  trans(lang.バンド),
  trans(lang.フォーク),
  trans(lang.スプーン),
  trans(lang.ボトル),
  trans(lang.缶),
  trans(lang.カップ),
  trans(lang.フタ),
  trans(lang.キャップ),
  trans(lang.ストロー),
  trans(lang.中栓),

];
