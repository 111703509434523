import {
  createMaxRule,
  typeNumberRule,
  requiredOnChangeRule,
  createDecimalRule,
  createRangeRule,
  requiredOnBlurRule, createMaxStringRule, requiredRule, createProhibitedCharsRule
} from '@/utils/validation-rules';

import ObjectUtils from "@/utils/object-utils";
import AdditiveEntity, {AdditivePurposeSynonymEntity, AdditiveSynonymEntity} from "@/entities/additive-entity";
import RowKey, {HasRowKey} from "@/entities/concerns/rowkey";
import {GmoEntity} from "@/entities/ingredient-item-gmo-entity";
import {TAdditiveSynonymListItem} from "@/repositories/master/additive-repository";
import cloneDeep from "lodash/cloneDeep";
import {HasChildren} from "@/utils/tree-utils";
import AllergyEntity, {AllergenList, getAllergensByIds, IAllergen} from "@/entities/allergy-entity";
import {IHasAdditive} from "@/entities/ingredient-additive-entity";
import {CarryoverReason} from "@/entities/carryover-entity";
import ProductDisplayServiceAdditive from "@/services/product-display-service-additive";
import uniqBy from "lodash/uniqBy";
import omit from "lodash/omit";
import StringUtil from "@/utils/string-utils";
import {i18n} from "@/bootstraps/locale";
import IFile, {IFileDefaultValue} from "@/contracts/i-file";

export default class SpecIngredientEntity implements HasRowKey, HasChildren<SpecIngredientEntity>, IHasAdditive {
  public id!:number;
  public parentId!:number|null;
  public referencedSpecId: number|null = null;
  public referencedSpecIngredientId: number|null = null;
  public children:SpecIngredientEntity[] = [];
  public type:SpecIngredientType = SpecIngredientType.Ingredient;

  public ingredientName:string|null = null;

  public additive:AdditiveEntity|null = null;
  public additiveSynonymId:number|null = null;
  public additiveSynonym:AdditiveSynonymEntity|null = null;
  public additivePurposeSynonymId:number|null = null;
  public additivePurposeSynonym:AdditivePurposeSynonymEntity|null = null;

  public amount!:number|null;
  public visible:boolean = true;
  public invisibleReasonType:CarryoverReason|null = null;
  public displayName:string|null = null;
  public note:string|null = null;

  public madeInArea:string|null = null;
  public manufacturerName:string|null = null;
  public supplierName:string|null = null;

  public hasAllergen:boolean|null = null;
  public hasGmo:boolean|null = null;

  public allergens: SpecIngredientAllergenEntity[] = [];
  public gmos:SpecIngredientGmoEntity[] = [];
  public origins:SpecIngredientOriginEntity[] = [];

  public customValue1: string|null = null;
  public customValue2: string|null = null;
  public customValue3: string|null = null;

  public rowKey!:number;

  public $dropdownVisible = false;
  public $isDisplayNameChanged = false;
  public $allergenDialogVisible:boolean = false;
  public $gmoDialogVisible:boolean = false;
  public $originDialogVisible:boolean = false;

  public hiddenBySubmitterFields:string[] = [];

  // only on show
  public amountInTotal!:number|null;
  public amountInDirect!:number|null;
  public positionNumber!:string;

  public getAmount(isAmountInTotal: boolean): number|null {
    return isAmountInTotal ? this.amountInTotal : this.amountInDirect;
  }

  public get positionLevel(): number {
    return this.positionNumber.split('-').length;
  }
  public get isPositionRoot(): boolean {
    return this.positionLevel === 1;
  }

  constructor(init:Partial<SpecIngredientEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, omit(init, 'additiveId'));

    if (init.displayName) {
      this.$isDisplayNameChanged = true;
    }

    if (init.additive) {
      this.additive = new AdditiveEntity(init.additive);
    }
    if (init.additiveSynonym) {
      this.additiveSynonym = new AdditiveSynonymEntity(init.additiveSynonym);
    }
    if (init.additivePurposeSynonym) {
      this.additivePurposeSynonym = new AdditivePurposeSynonymEntity(init.additivePurposeSynonym);
    }

    // ImportFromProduct用
    if (init.children) {
      this.children = init.children.map(c => new SpecIngredientEntity(c));
    }

    if (init.allergens) {
      this.allergens = init.allergens.map(a => new SpecIngredientAllergenEntity(a));
    }

    if (init.gmos) {
      this.gmos = init.gmos.map(a => new SpecIngredientGmoEntity(a));
    }
    if (init.origins) {
      this.origins = init.origins.map(a => new SpecIngredientOriginEntity(a));
    }

    this.rowKey = RowKey.getRowKey();

    Object.keys(HideableFieldsDict).forEach(key => {
      if (init[StringUtil.toCamelCase(key)] === undefined) {
        this.hiddenBySubmitterFields.push(key);
      }
    });
  }

  public get additiveId() {
    return this.additiveSynonym ? this.additiveSynonym.additiveId : null;
  }

  public get isEmpty():boolean {
    const isEmpty = !this.ingredientName
      && !this.additiveSynonymId
      && !this.amount
      && !this.manufacturerName
      && !this.supplierName
      && !this.madeInArea
      && !this.displayName
      && !this.note
      && this.nonBlankSpecIngredientAllergens.length === 0
      && this.gmos.filter(g => !g.isEmpty).length === 0
      && this.origins.filter(o => !o.isEmpty).length === 0;
    return isEmpty;
  }
  public get isInvalid(): boolean {
    if (this.hasAnyChildren) return false;
    return this.origins.some(o => o.invalid)
      || this.allergens.some(g => g.invalid)
      || this.gmos.some(g => g.invalid);
  }
  public get isIngredient(): boolean {
    return this.type === SpecIngredientType.Ingredient;
  }
  public get isAdditive(): boolean {
    return this.type === SpecIngredientType.Additive;
  }
  public get isAdditiveFormulation(): boolean {
    return this.type === SpecIngredientType.AdditiveFormulation;
  }
  public get foodTypeLabel(): string | null {
    if (!this.type) return null;

    return {
      [SpecIngredientType.Ingredient]: i18n.t('原材料'),
      [SpecIngredientType.Additive]: i18n.t('添加物'),
      [SpecIngredientType.AdditiveFormulation]: i18n.t('製剤'),
    }[this.type];
  }

  public getLabelName(): string {
    if (this.isAdditive) {
      return ProductDisplayServiceAdditive.getAdditiveDisplayName(this, {isAllergenSummarized: true, isRepeatedAllergenOmitted: false});
    } else if (this.isAdditiveFormulation) {
      return '';
    } else {
      return (this.displayName || '');
    }
  }

  public get hasAnyChildren():boolean { // hasChildrenだとelTableのpropsとかぶる可能性あるから注意！
    return this.children.length > 0;
  }
  public isFieldHidden(field:string): boolean {
    return this.hiddenBySubmitterFields.includes( StringUtil.toSnakeCase(field) );
  }

  public serialize(): SpecIngredientEntity {
    const data = cloneDeep(this) as SpecIngredientEntity;
    data.amount = data.amount || 0; // 親の場合はnullになる可能性ある
    data.displayName = this.getLabelName();
    data.additive = null;
    data.additiveSynonym = null;
    data.additivePurposeSynonym = null;
    if (data.visible) {
      data.invisibleReasonType = null;
    }
    if (data.hasAnyChildren) {
      data.hasAllergen = this.hasAllergenRecursive();
      data.hasGmo = this.hasGmoRecursive();
      data.allergens = [];
      data.gmos = [];
      data.origins = [];
    } else {
      if (data.hasAllergen === false) {
        data.allergens = [];
      } else {
        data.allergens = data.allergens.filter(a => !a.isBlank);
      }
      if (data.hasGmo === false) {
        data.gmos = [];
      } else {
        data.gmos = data.gmos.filter(g => !g.isEmpty);
      }

      data.origins = data.origins.filter(g => !g.isEmpty);
    }
    data.children = data.children.map(c => c.serialize())
    return data;
  }

  public getMaterialName(additiveSynonymList:TAdditiveSynonymListItem[]): string {
    const row = this;
    if (row.isIngredient) return row.ingredientName || '';
    if (row.additiveSynonymId) {
      const as = additiveSynonymList.find(s => s.synonymId === row.additiveSynonymId);
      if (!as) return '';
      const append = (this.type === SpecIngredientType.AdditiveFormulation)  ? i18n.t("製剤") : '';
      return as ? (as.label + append) : '';
    }
    return '';
  }

  public getOriginAllergens(): AllergyEntity[] {
    return this.allergenEntities;
  }
  public get nonBlankSpecIngredientAllergens(): SpecIngredientAllergenEntity[] {
    return this.allergens.filter(a => !a.isBlank);
  }
  public get allergenEntities() :AllergyEntity[] {
    if (!this.hasAllergen) return [];
    return this.allergens.map(a => new AllergyEntity(a.allergen));
  }
  public getAllergenDisplayRecursive(showAnyway: boolean = false) :string[] {
    if (!showAnyway && !this.hasAllergen) return [];
    return this.getAllergensRecursive()
      .map(a => {
        const reason = a.invisibleReason ? `、${a.invisibleReason}` : '';
        const prefix = a.isVisible ? '' : `（表示不要${reason}）`;
        return a.allergen.name + prefix;
      });
  }
  public getAllergensRecursive(): SpecIngredientAllergenEntity[] {
    return this.hasAnyChildren
      ? uniqBy(
        this.children.flatMap(c => c.getAllergensRecursive()),
        (a:SpecIngredientAllergenEntity) => a.allergyId + (a.isVisible ? 'yes' : 'no')
      )
      : this.nonBlankSpecIngredientAllergens;
  }
  public hasAllergenRecursive(): boolean | null {
    return this.hasPropRecursive('hasAllergen');
  }
  public hasGmoRecursive(): boolean | null {
    return this.hasPropRecursive('hasGmo');
  }
  public hasPropRecursive(prop:string): boolean | null {
    if (!this.hasAnyChildren) return this[prop];
    if (this.children.some(c => c.hasPropRecursive(prop) === true )) {
      return true;
    } else if (this.children.every(c => c.hasPropRecursive(prop) === false)) {
      return false;
    } else {
      return null;
    }
  }
  public getGmosRecursive(excludeEmpty = true): SpecIngredientGmoEntity[] {
    if (!this.hasGmo) return [];

    const after = (res:SpecIngredientGmoEntity[]) => excludeEmpty ? res.filter(g => !g.isEmpty) : res;
    return this.children.length === 0 ?
      this.gmos :
      after(this.children.flatMap(c => c.getGmosRecursive()));
  }
  public getOriginsRecursive(excludeEmpty = true): SpecIngredientOriginEntity[] {
    const after = (res:SpecIngredientOriginEntity[]) => excludeEmpty ? res.filter(g => !g.isEmpty) : res;
    return this.children.length === 0 ?
      this.origins :
      after(this.children.flatMap(c => c.getOriginsRecursive()));
  }
}

export enum SpecIngredientType {
  Ingredient = 1,
  Additive = 2,
  AdditiveFormulation = 3,
}

export class SpecIngredientAllergenEntity {
  public allergyId!: number;
  public isVisible: boolean = true;
  public invisibleReason: string | null = null;

  public rowKey!:number;

  constructor(init:Partial<SpecIngredientAllergenEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);

    this.rowKey = RowKey.getRowKey();
  }
  public get allergen(): IAllergen {
    return AllergenList.find(a => a.id == this.allergyId)!;
  }
  public get isBlank(): boolean {
    return !this.allergyId && !this.invisibleReason;
  }

  public get invalid(): boolean {
    return !this.allergyId && !!this.invisibleReason;
  }
}

export class SpecIngredientGmoEntity extends GmoEntity {
  public id!:number;
  public rowKey!:number;
  public ipAttachment: IFile = IFileDefaultValue;

  constructor(init:Partial<SpecIngredientGmoEntity> = {}) {
    super(init);
    ObjectUtils.assignLiteralFields(this, init);

    this.rowKey = RowKey.getRowKey();

    if (init.ipAttachment) {
      this.ipAttachment = init.ipAttachment;
    }
  }

  public static createFromBase(base:GmoEntity) {
    return new SpecIngredientGmoEntity(base);
  }

  public get hasIpAttachment(): boolean {
    return !!this.ipAttachment && !!this.ipAttachment.filename;
  }
}

export class SpecIngredientOriginEntity {
  public name!:string;
  public madeInArea:string|null = null;

  public rowKey!:number;

  public get isEmpty():boolean {
    return !this.name && !this.madeInArea;
  }
  public get invalid():boolean {
    return !this.name && !!this.madeInArea;
  }

  public get label():string {
    const name = this.name || '';
    return this.madeInArea ? `${name}（${this.madeInArea}）` : name;
  }

  constructor(init:Partial<SpecIngredientOriginEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);

    this.rowKey = RowKey.getRowKey();
  }
}

export enum HideableFields {
  Amount = 'amount',
  ManufacturerName = 'manufacturer_name',
  SupplierName = 'supplier_name',
}
export const HideableFieldsDict = {
  [HideableFields.Amount]: '原材料：割合',
  [HideableFields.ManufacturerName]: '原材料：製造者名',
  [HideableFields.SupplierName]: '原材料：仕入元名'
}

export const ValidatorRules = {
  ingredientName: [requiredOnBlurRule, createMaxRule(128, 'char')],
  displayName: [
    createMaxRule(128, 'char'),
    createProhibitedCharsRule(
      ['(', ')','（','）', ' ', '　'],
      'blur',
      "「一括表示上の表示名」は該当階層のみ入力、また「原産地」や「遺伝子組換え対象農作物」は、表右側のそれぞれの列に記入ください。"
    )
  ],
  additiveSynonymId: [requiredOnChangeRule],
  amount: [requiredOnBlurRule, typeNumberRule, createRangeRule(0, 100, 'number'), createDecimalRule(11, 6)],
  supplierName: [],
  manufacturerName: [],
  customValue: [createMaxStringRule()],
  hasAllergen: [],
  allergens: {
    allergyId: [requiredRule],
    invisibleReason: [createMaxStringRule()],
  },
  hasGmo: [],
  madeInArea: [],
};

