import NutritionEntity, {BasicNutritionList, BasicNutritionProps} from "@/entities/nutrition-entity";

import round from 'lodash/round';
import pick from 'lodash/pick';
import {NutritionValue} from "@/entities/nutrition-value";

export default class IngredientNutritionEntity {
  public id:number|null = null;
  public ingredientId:number|null = null;
  public referredNutritionId:number|null = null;
  public isDetail: boolean = false;

  public calorie:NutritionValue = new NutritionValue(null);
  public lipid:NutritionValue = new NutritionValue(null);
  public protein:NutritionValue = new NutritionValue(null);
  public carb:NutritionValue = new NutritionValue(null);
  public natrium:NutritionValue = new NutritionValue(null);
  public water:NutritionValue = new NutritionValue(null)
  public ash:NutritionValue = new NutritionValue(null);
  public salt:NutritionValue = new NutritionValue(null, 'salt');

  public saturatedFattyAcids:NutritionValue = new NutritionValue(null);
  public monoUnsaturatedFattyAcids:NutritionValue = new NutritionValue(null);
  public polyUnsaturatedFattyAcids:NutritionValue = new NutritionValue(null);
  public omega3:NutritionValue = new NutritionValue(null);
  public omega6:NutritionValue = new NutritionValue(null);
  public transFattyAcids:NutritionValue = new NutritionValue(null);
  public cholesterol:NutritionValue = new NutritionValue(null);

  public sugar:NutritionValue = new NutritionValue(null);
  public saccharides:NutritionValue = new NutritionValue(null);
  public fiber:NutritionValue = new NutritionValue(null);
  public solubleFiber:NutritionValue = new NutritionValue(null);
  public insolubleFiber:NutritionValue = new NutritionValue(null);

  public zinc:NutritionValue = new NutritionValue(null);
  public potassium:NutritionValue = new NutritionValue(null);
  public calcium:NutritionValue = new NutritionValue(null);
  public chromium:NutritionValue = new NutritionValue(null);
  public selenium:NutritionValue = new NutritionValue(null);
  public iron:NutritionValue = new NutritionValue(null);
  public copper:NutritionValue = new NutritionValue(null);
  public magnesium:NutritionValue = new NutritionValue(null);
  // public natrium:NutritionValue;
  public manganese:NutritionValue = new NutritionValue(null);
  public molybdenum:NutritionValue = new NutritionValue(null);
  public iodine:NutritionValue = new NutritionValue(null); //ヨウ素
  public phosphorous:NutritionValue = new NutritionValue(null); //リン

  public niacin:NutritionValue = new NutritionValue(null);
  public folicAcid:NutritionValue = new NutritionValue(null);
  public pantothenicAcid:NutritionValue = new NutritionValue(null);
  public biotin:NutritionValue = new NutritionValue(null);
  public vitaminB1:NutritionValue = new NutritionValue(null);
  public vitaminB2:NutritionValue = new NutritionValue(null);
  public vitaminB6:NutritionValue = new NutritionValue(null);
  public vitaminB12:NutritionValue = new NutritionValue(null);
  public vitaminC:NutritionValue = new NutritionValue(null);
  public vitaminD:NutritionValue = new NutritionValue(null);
  public vitaminK:NutritionValue = new NutritionValue(null);

  public vitaminA:NutritionValue = new NutritionValue(null);
  public retinol:NutritionValue = new NutritionValue(null);
  public alphaCarotene:NutritionValue = new NutritionValue(null);
  public betaCarotene:NutritionValue = new NutritionValue(null);
  public betaCryptoxanthin:NutritionValue = new NutritionValue(null);
  public betaCaroteneEquivalent:NutritionValue = new NutritionValue(null);
  public alphaTocopherol:NutritionValue = new NutritionValue(null);
  public betaTocopherol:NutritionValue = new NutritionValue(null);
  public gammaTocopherol:NutritionValue = new NutritionValue(null);
  public deltaTocopherol:NutritionValue = new NutritionValue(null);
  public useNatriumInsteadOfSalt:boolean = false;

  constructor(init:Partial<IngredientNutritionEntity> = {}) {
    this.id = init.id!;
    this.ingredientId = init.ingredientId || null;
    this.referredNutritionId = init.referredNutritionId || null;
    this.useNatriumInsteadOfSalt = init.useNatriumInsteadOfSalt || false;
    this.isDetail = init.isDetail || false;

    this.getNutritionKeys(init).forEach(k => {
      if (init[k] instanceof NutritionValue) {
        this[k] = init[k];
      } else {
        this[k] = new NutritionValue(init[k], k);
      }
    });
  }

  public getValue(prop: string): NutritionValue {
    if (prop === 'saltValue') {
      return this.saltValue;
    }
    if (!this.isDetail && !BasicNutritionProps.includes(prop)) {
      return NutritionValue.None;
    }
    return this[prop] as NutritionValue;
  }

  public get saltValue():NutritionValue {
    return this.useNatriumInsteadOfSalt ?
      this.natrium.getSaltValueOfNatrium() :
      this.salt;
  }

  public setValueFromNutrition(nutrition:NutritionEntity) {
    this.getNutritionKeys(this).forEach(k => {
      if (k in nutrition) {
        (this[k] as NutritionValue).original = nutrition[k].original;
      } else {
        (this[k] as NutritionValue).original = null;
      }
    });
  }

  public equalToNutrition(nutrition: NutritionEntity) {
    return this.getNutritionKeys(this).every(k => {
      if (!this[k]) {
        console.log(k);
      }
      return this[k].equal(nutrition[k] || null);
    });
  }

  public serialize(): IngredientNutritionEntity {
    const nutriValues = pick(this, this.getNutritionKeys(this));
    Object.keys(nutriValues).forEach(key => {
      nutriValues[key] = nutriValues[key].original;
    });

    return Object.assign(pick(this, this.nonNutritionKeys), nutriValues) as any;
  }

  protected get nonNutritionKeys() {
    return ['id','ingredientId','referredNutritionId','referredNutrition', 'useNatriumInsteadOfSalt', 'isDetail'];
  }
  protected getNutritionKeys(obj) {
    return Object.keys(obj).filter(k => !this.nonNutritionKeys.includes(k));
  }

}


export const ValidatorRules:any = {
  nutrition: [{
    trigger: 'change',
    validator: (rule, value, callback) => {
      const nv = new NutritionValue(value);

      if (nv.isNoneOrBlank) return callback();

      if (value.length > 16) return callback(new Error('16文字以下にしてください'));

      if (nv.isCalculatable) return callback();

      return callback(new Error('数値を入力してください'));
    }
  }],
};
