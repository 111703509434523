export const SpecQualityPreservationMethodLang = {
  '脱酸素剤': {
    ja: "脱酸素剤",
    en: "Oxygen absorber",
    cn: "除氧剂"
  },
  'アルコール蒸散剤': {
    ja: "アルコール蒸散剤",
    en: "Alcohol evaporator",
    cn: "酒精蒸腾剂"
  },
  '乾燥剤': {
    ja: "乾燥剤",
    en: "Desiccant",
    cn: "干燥剂"
  },
  '保鮮剤': {
    ja: "保鮮剤",
    en: "Freshness retaining agent",
    cn: "保鲜剂"
  },
  'ガス置換': {
    ja: "ガス置換",
    en: "Gas replacement",
    cn: "气体置换"
  },
  '真空': {
    ja: "真空",
    en: "Vacuum",
    cn: "真空"
  },
  '含気': {
    ja: "含気",
    en: "Air inclusion",
    cn: "气体形成"
  },
};
