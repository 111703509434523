import {getTextByLang as t} from "@/bootstraps/locale";

export const SpecPackageImagePreparedLang = {
  '（ピース）正面': t({
    ja: "（ピース）正面",
    en: "(Piece) Front",
    cn: "（pc）正面"
  }),
  '（ピース）裏面': t({
    ja: "（ピース）裏面",
    en: "(Piece) Back",
    cn: "（pc）背面"
  }),
  '（ピース）天面': t({
    ja: "（ピース）天面",
    en: "(Piece) Top",
    cn: "（pc）上面"
  }),
  '（ピース）底面': t({
    ja: "（ピース）底面",
    en: "(Piece) Bottom",
    cn: "（pc）底面"
  }),
  '（ピース）右側面': t({
    ja: "（ピース）右側面",
    en: "(Piece) Right side",
    cn: "（pc）右侧面"
  }),
  '（ピース）左側面': t({
    ja: "（ピース）左側面",
    en: "(Piece) Left side",
    cn: "（pc）左侧面"
  }),
  '（ピース）一括表示ラベル': t({
    ja: "（ピース）一括表示ラベル",
    en: "(Piece) Food Label",
    cn: "（pc）批量标示标签"
  }),
  '（ピース）内側': t({
    ja: "（ピース）内側",
    en: "(Piece) Inside",
    cn: "（pc）内侧"
  }),
  '最外装': t({
    ja: "最外装",
    en: "Outermost package",
    cn: "最外面包装"
  }),

};
