import {createDecimalRule, createMaxStringRule, requiredRule} from '@/utils/validation-rules';

import ObjectUtils from "@/utils/object-utils";
import {XRayDetectionMaterialsLang as lang} from "@/lang/enum/x-ray-detection-material-lang";

export default class SpecXRayDetectionEntity {
  public id!:number;
  public material:string|null = null;
  public ballPhi:number|null = null;
  public linePhi:number|null = null;
  public lineLength:number|null = null;
  public note:string|null = null;

  constructor(init:Partial<SpecXRayDetectionEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);
  }
}

export const ValidatorRules = {
  material: [requiredRule, createMaxStringRule() ],
  ballPhi: [createDecimalRule(11, 6) ],
  linePhi: [createDecimalRule(11, 6) ],
  lineLength: [createDecimalRule(11, 6) ],
  note: [createMaxStringRule() ],
};

export const SpecXRayDetectionMaterialTypes = [
  // 金属
  lang['鉄（Fe）'] ,
  lang['ステンレス（SUS）'] ,
  lang['アルミニウム'] ,
  // ガラス
  lang['ガラス（ソーダ）'],
  lang['ガラス（石英）'],
  // その他
  lang['EPDMゴム'],
  lang['フッ素樹脂'],
  lang['石'],
  lang['セラミック'],
  lang['貝殻'],
  lang['骨'],
];
