import {getTextByLang as t} from "@/bootstraps/locale";

export const PreservationMethodLang = {
  '直射日光を避け、保存してください': t({
    ja: "直射日光を避け、保存してください",
    en: "Store away from direct sunlight.",
    cn: "请避免阳光直射下储存"
  }),
  '直射日光を避け、常温で保存してください': t({
    ja: "直射日光を避け、常温で保存してください",
    en: "Store at room temperature away from direct sunlight.",
    cn: "请避免阳光直射下并在室温下储存"
  }),
  '直射日光を避け、涼しい場所で保存してください': t({
    ja: "直射日光を避け、涼しい場所で保存してください",
    en: "Store in a cool place away from direct sunlight.",
    cn: "请避免阳光直射并在阴凉的地方储存"
  }),
  '直射日光を避け、冷蔵庫で保存してください': t({
    ja: "直射日光を避け、冷蔵庫で保存してください",
    en: "Store in the refrigerator away from direct sunlight.",
    cn: "请避免阳光直射并存放于冰箱中"
  }),
  '常温': t({
    ja: "常温",
    en: "Room temperature",
    cn: "室温"
  }),
  '常温（28℃以下）': t({
    ja: "常温（28℃以下）",
    en: "Room temperature (below 28°C)",
    cn: "室温（28℃以下）"
  }),
  '要冷蔵': t({
    ja: "要冷蔵",
    en: "Refrigerated",
    cn: "需冷藏"
  }),
  '要冷蔵（10℃以下）': t({
    ja: "要冷蔵（10℃以下）",
    en: "Refrigerated (10℃ or less)",
    cn: "需冷藏（10℃以下）"
  }),
  '要冷蔵（4℃以下）': t({
    ja: "要冷蔵（4℃以下）",
    en: "Refrigerated (4℃ or less)",
    cn: "需冷藏（4℃以下）"
  }),
  '要冷蔵（-5℃以下）': t({
    ja: "要冷蔵（-5℃以下）",
    en: "Refrigerated (-5℃ or less)",
    cn: "需冷藏（-5℃以下）"
  }),
  '要冷凍': t({
    ja: "要冷凍",
    en: "Frozen",
    cn: "需冷冻"
  }),
  '要冷凍（-15℃以下）': t({
    ja: "要冷凍（-15℃以下）",
    en: "Frozen (-15℃ or less)",
    cn: "需冷冻（-15℃以下）"
  }),
  '要冷凍（-18℃以下）': t({
    ja: "要冷凍（-18℃以下）",
    en: "Frozen (-18℃ or less)",
    cn: "需冷冻（-18℃以下）"
  }),
  '常温で保存すること': t({
    ja: "常温で保存すること",
    en: "Store at room temperature.",
    cn: "在室温下储存"
  }),
  '常温（28℃以下）で保存すること': t({
    ja: "常温（28℃以下）で保存すること",
    en: "Store at room temperature (below 28°C).",
    cn: "在室温下（28℃以下）储存"
  }),
  '要冷蔵で保存すること': t({
    ja: "要冷蔵で保存すること",
    en: "Keep refrigerated.",
    cn: "需存放在冰箱"
  }),
  '要冷蔵（10℃以下）で保存すること': t({
    ja: "要冷蔵（10℃以下）で保存すること",
    en: "Keep refrigerated (10°C or less).",
    cn: "需存放在冰箱（10℃以下）"
  }),
  '要冷蔵（4℃以下）で保存すること': t({
    ja: "要冷蔵（4℃以下）で保存すること",
    en: "Keep refrigerated (4°C or less).",
    cn: "需存放在冰箱（4℃以下）"
  }),
  '要冷蔵（-5℃以下）で保存すること': t({
    ja: "要冷蔵（-5℃以下）で保存すること",
    en: "Keep refrigerated (-5°C or less).",
    cn: "需存放在冰箱（-5℃以下）"
  }),
  '要冷凍で保存すること': t({
    ja: "要冷凍で保存すること",
    en: "Keep frozen.",
    cn: "需存放在冷冻箱"
  }),
  '要冷凍（-15℃以下）で保存すること': t({
    ja: "要冷凍（-15℃以下）で保存すること",
    en: "Keep frozen (-15°C or less).",
    cn: "需存放在冷冻箱（-15℃以下）"
  }),
  '要冷凍（-18℃以下）で保存すること': t({
    ja: "要冷凍（-18℃以下）で保存すること",
    en: "Keep frozen (-18°C or less).",
    cn: "需存放在冷冻箱（-18℃以下）"
  }),
};
