import {IMergedNormalizedItem} from "@/services/product-display-service";
import {CompanySettingEntity} from "@/entities/company-setting-entity";
import ProductDisplaySetting from "@/entities/product-display-setting";

export default class ProductDisplayServiceGMO {
  public static getGMOLabels(item: IMergedNormalizedItem, brothers: IMergedNormalizedItem[], companySetting: CompanySettingEntity, productSetting: ProductDisplaySetting) {
    const gmos = item.gmos;

    if (!gmos.length) return '';

    if (productSetting.showOnlyMinGmos) return '';

    // https://www.caa.go.jp/policies/policy/food_labeling/information/qa/common_03/#a1-01
    // 遺伝子組換え農産物が主な原材料(原材料の上位3位以内で、かつ、全重量の5%以上を占める)でない場合は表示義務はありません。
    // => 兄弟原材料のうちの4位以下または全重量の5%未満なら表示しない
    if (brothers.indexOf(item) >= 3 || item.concentrationAmountRatioInRootProduct < 0.05) {
      return '';
    }

    if (item.ingredients.some(i => !i.isFresh)) {
      // 加工なら「サラダ（大豆（遺伝子組換え）、とうもろこし（遺伝子組換えでない））」のようにCropTypeも表示する
      const gmosStrs = gmos.map(gmo => `${gmo.cropTypeLabel}（${gmo.getGmoTypeLabel(companySetting)}）`);
      return `（${gmosStrs.join('、')}）`;
    } else {
      // 生鮮なら「大豆（遺伝子組換え）」のようにGmoTypeだけ表示する
      return `（${gmos[0].getGmoTypeLabel(companySetting)}）`;
    }
  }
}

