import {
  requiredOnBlurRule, createMaxStringRule,
} from '@/utils/validation-rules';

import ObjectUtils from "@/utils/object-utils";
import {getTextByLang} from "@/bootstraps/locale";
import { SpecQualityPreservationMethodLang} from "@/lang/enum/spec-quality-preservation-method-lang";

export default class SpecQualityPreservationMethodEntity {
  public id!:number;
  public type:string|null = null;
  public name:string|null = null;

  constructor(init:Partial<SpecQualityPreservationMethodEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);
  }

  public get isEmpty():boolean {
    return !this.type && !this.name;
  }
}

export const ValidatorRules = {
  type: [requiredOnBlurRule, createMaxStringRule()],
  name: [requiredOnBlurRule, createMaxStringRule()],
};

export const QualityPreservationMethodTypes = [
  getTextByLang(SpecQualityPreservationMethodLang.脱酸素剤),
  getTextByLang(SpecQualityPreservationMethodLang.アルコール蒸散剤),
  getTextByLang(SpecQualityPreservationMethodLang.乾燥剤),
  getTextByLang(SpecQualityPreservationMethodLang.保鮮剤),
  getTextByLang(SpecQualityPreservationMethodLang.ガス置換),
  getTextByLang(SpecQualityPreservationMethodLang.真空),
  getTextByLang(SpecQualityPreservationMethodLang.含気),
];
