import ObjectUtils from "@/utils/object-utils";

export default class SpecReferringSpecEntity {
  public referredSpecId!:number|null;
  public referredSpecAcceptanceSpecId!:number|null;

  public readonly referredMetaId!:number;

  constructor(init:Partial<SpecReferringSpecEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);
  }

  public get isAcceptance():boolean {
    return !!this.referredSpecAcceptanceSpecId;
  }
  public get referredId(): number|null {
    return this.referredSpecId || this.referredSpecAcceptanceSpecId;
  }
}
