import {createMaxStringRule} from '@/utils/validation-rules';

import ObjectUtils from "@/utils/object-utils";

export default class SpecManufactureProcessEntity {
  public id!:number;

  public processName:string|null = null;
  public managementTarget:string|null = null;
  public managementPoint:SpecManufactureProcessManagementPointType|null = null;
  public standard:string|null = null;
  public monitoringMethod:string|null = null;
  public hazard:string|null = null;
  public note:string|null = null;

  public lawCriteria:string|null = null;
  public monitoringPerson:string|null = null;
  public monitoringTool:string|null = null;
  public monitoringFrequency:string|null = null;
  public measureOnHazard:string|null = null;
  public hazardJudgePerson:string|null = null;
  public hazardEffect:string|null = null;
  public validationPerson:string|null = null;
  public validationTarget:string|null = null;
  public validationFrequency:string|null = null;
  public validationMethod:string|null = null;
  public correctiveAction:string|null = null;
  public correctivePerson:string|null = null;
  public documentName:string|null = null;

  constructor(init:Partial<SpecManufactureProcessEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);
  }
  public get managementPointLabel(): string {
    if (this.managementPoint === null) return '';
    return SpecManufactureProcessManagementPointDict[this.managementPoint];
  }
}

export const ValidatorRules = {
  processName: [createMaxStringRule()],
  managementTarget: [createMaxStringRule()],
  standard: [createMaxStringRule()],
  monitoringMethod: [createMaxStringRule()],
  hazard: [createMaxStringRule()],
  note: [createMaxStringRule()],

  lawCriteria: [createMaxStringRule()],
  monitoringPerson: [createMaxStringRule()],
  monitoringTool: [createMaxStringRule()],
  monitoringFrequency: [createMaxStringRule()],
  measureOnHazard: [createMaxStringRule()],
  hazardJudgePerson: [createMaxStringRule()],
  hazardEffect: [createMaxStringRule()],
  validationPerson: [createMaxStringRule()],
  validationTarget: [createMaxStringRule()],
  validationFrequency: [createMaxStringRule()],
  validationMethod: [createMaxStringRule()],
  correctiveAction: [createMaxStringRule()],
  correctivePerson: [createMaxStringRule()],
  documentName: [createMaxStringRule()],
};

export const SpecManufactureProcessManagementPointDict = {
  1: 'PRP',
  2: 'OPRP',
  3: 'CCP',
}
export type SpecManufactureProcessManagementPointType = keyof typeof SpecManufactureProcessManagementPointDict;

export const ProcessNameDict = [
  '原料受入',
  '原料保管',
  '箱詰め',
  '包装',
  '洗浄',
  '加熱処理',
  '冷却',
  '急速冷凍',
  'トリミング',
  'ブレッディング',
  '蒸煮',
  'カット',
  '裏ごし',
  '金属',
];

export const ProcessHazardDict = [
  '微生物の増殖',
  '金属異物の残存',
  '異物の残存',
];

