import ObjectUtils from "@/utils/object-utils";
import IFile, {IFileDefaultValue} from "@/contracts/i-file";

export default class SpecAttachmentEntity {
  public id!:number;
  public specId!:number;
  public type!:SpecAttachmentType;
  public key!:string;
  public attachment: IFile = IFileDefaultValue;

  constructor(init:Partial<SpecAttachmentEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);

    if (init.attachment) {
      this.attachment = init.attachment;
    }
  }

  public get isPackageImage():boolean {
    return this.type === SpecAttachmentType.PackageImage;
  }

  public clear() {
    this.attachment.filename = '';
    this.attachment.body = '';
    this.attachment.url = '';
  }
}
export enum SpecAttachmentType {
  PackageImage = 1,
  Document = 2,
}

import {SpecPackageImagePreparedLang as langP} from "@/lang/enum/spec-package-image-prepared-lang";
import {i18n} from "@/bootstraps/locale";
export const SpecPackageImagePreparedType = {
  PIECE_FRONT : langP['（ピース）正面'],
  PIECE_BACK : langP['（ピース）裏面'],
  PIECE_TOP : langP['（ピース）天面'],
  PIECE_BOTTOM : langP['（ピース）底面'],
  PIECE_RIGHT : langP['（ピース）右側面'],
  PIECE_LEFT : langP['（ピース）左側面'],
  PIECE_LABEL : langP['（ピース）一括表示ラベル'],
  PIECE_INNER : langP['（ピース）内側'],
  OUTERMOST : langP['最外装'],
}

export const SpecManufactureAttachmentPreparedType = {
  HYGIENE_CHECK_SHEET: i18n.t('衛生監視票') as string,
  MICROBE_TEST_RESULTS: i18n.t('微生物検査結果') as string,
  HEAVY_METAL_TEST_RESULTS: i18n.t('重金属試験結果') as string,
  QUALITY_EVIDENCE1: i18n.t('製造品質添付資料1') as string,
  QUALITY_EVIDENCE2: i18n.t('製造品質添付資料2') as string,
  CULTIVATION_HISTORY: i18n.t('栽培履歴') as string,
  RESIDUAL_PESTICIDE_TEST_RESULTS: i18n.t('残留農薬試験結果') as string,
};

export const SpecDocumentAttachmentPreparedType = {
  ORIGIN_CERTIFICATION: i18n.t('産地証明書') as string,
  MANUFACTURE_PROCESS: i18n.t('製造工程') as string,
  ...SpecManufactureAttachmentPreparedType
}

