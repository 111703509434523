import uniqBy from "lodash/uniqBy";
import AllergyEntity from "@/entities/allergy-entity";
import {all} from "axios";

const usedAllergenIds:number[] = [];

export default class ProductDisplayServiceAllergen {
  public static init(){
    usedAllergenIds.splice(0);
  }

  // アレルゲンの一括表示
  public static sortAndFilterForDisplay(allergens: AllergyEntity[]): AllergyEntity[] {
    allergens.sort((a,b) => a.order - b.order);
    const allergensToShow = allergens.filter(a => !a.excludeOnLabelDisplay);
    return uniqBy(allergensToShow, a => a.id);
  }

  // 個別表記のアレルゲンを原材料名の後ろ
  public static getAllergenSuffix(allergens: AllergyEntity[], isAllergenSummarized:boolean, isRepeatedAllergenOmitted:boolean) {
    if(isAllergenSummarized) return '';
    const filtered = ProductDisplayServiceAllergen.sortAndFilterForDisplay(allergens);
    const names = ProductDisplayServiceAllergen.getAllergenNames(filtered, isRepeatedAllergenOmitted, false);
    return names.length ? `（${names.join('・')}を含む）` : '';
  }

  public static getAllergenNames(allergens:AllergyEntity[], omitRepeatedAllergen:boolean, isForAdditive:boolean): string[] {
    let allergenUniq = uniqBy(allergens, a => a.id);

    if (omitRepeatedAllergen) {
      allergenUniq = allergenUniq.filter(a => usedAllergenIds.indexOf(a.id) == -1);
      usedAllergenIds.push(...allergenUniq.map(a => a.id));
    }

    return allergenUniq.map(a => a.getDisplayName(isForAdditive));
  }

}

