export default interface IFile {
  filename: string;
  body?: string|null; // only on upload
  __destroy?: boolean;
  url?: string; // only on fetch
}

export const IFileDefaultValue = { filename: "", body: "", url: ''};

export interface IFileUpload {
  filename: string;
  body: string;
}
