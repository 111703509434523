import {
  createMaxStringRule,
  requiredOnBlurRule
} from '@/utils/validation-rules';

import ObjectUtils from "@/utils/object-utils";
import RowKey from "@/entities/concerns/rowkey";

export default class SpecInspectionEntity {
  public id!:number;
  public type!:SpecInspectionType;

  public name!:string;
  public method:string|null = null;
  public frequency:string|null = null;
  public criteria:string|null = null;
  public value:string|null = null;
  public agency:string|null = null;
  public note:string|null = null;

  private rowKey!: number;

  constructor(init:Partial<SpecInspectionEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);
    this.name = init.name || '';

    this.rowKey = RowKey.getRowKey();
  }

  public get isEmpty():boolean {
    return !this.name && !this.method && !this.frequency && !this.criteria && !this.value && !this.agency && !this.note;
  }
}

export const ValidatorRules = {
  name: [requiredOnBlurRule, createMaxStringRule()],
  method: [createMaxStringRule()],
  frequency: [createMaxStringRule()],
  criteria: [createMaxStringRule()],
  value: [createMaxStringRule()],
  agency: [createMaxStringRule()],
  note: [createMaxStringRule()],
};

export enum SpecInspectionType {
  Physical = 1,
  Chemical = 2,
  Microbe = 3
}

export const SpecInspectionTypes = {
  [SpecInspectionType.Physical]: '物理',
  [SpecInspectionType.Chemical]: '理化学',
  [SpecInspectionType.Microbe]: '微生物',
};

