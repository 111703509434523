import {
  requiredOnBlurRule,
  createMaxStringRule
} from '@/utils/validation-rules';

import ObjectUtils from "@/utils/object-utils";

export default class SpecLabelCustomValueEntity {
  public id!:number;
  public name!:string;
  public value!:string;

  constructor(init:Partial<SpecLabelCustomValueEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);
  }

  public get isEmpty():boolean {
    return !this.name && !this.value;
  }
}

export const ValidatorRules = {
  name: [requiredOnBlurRule, createMaxStringRule()],
  value: [requiredOnBlurRule, createMaxStringRule()],
};


export const LabelCustomValueSelection = [
  '醸造酢の混合割合',
  '酸度',
  '希釈割合',
  '取扱上の注意',
  '形状',
];
