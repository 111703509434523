import {i18n} from "@/bootstraps/locale";

export enum NutritionDisplayFormat {
  EstimatedPerGram = 1,
  EstimatedPerGramWithParenthesis = 2,
  EstimatedPerGramWithUnit = 3,
  EstimatedPerUnit = 4,
  EstimatedPerUnitWithParenthesis = 5,
  PerGram = 11,
  PerGramWithParenthesis = 12,
  PerGramWithUnit = 13,
  PerUnit = 14,
  PerUnitWithParenthesis = 15,
}
export default interface ILabelNutrition {
  nutritionUnit:string|null;
  nutritionAmountPerUnit:number;
  nutritionIsGram:boolean;
  nutritionGramPerMilliliter:number;
  nutritionDisplayFormat:NutritionDisplayFormat;
}

export function doesNutritionLabelByFormatHasEstimation(format: NutritionDisplayFormat): boolean {
  return [
    NutritionDisplayFormat.EstimatedPerGram,
    NutritionDisplayFormat.EstimatedPerGramWithParenthesis,
    NutritionDisplayFormat.EstimatedPerGramWithUnit,
    NutritionDisplayFormat.EstimatedPerUnit,
    NutritionDisplayFormat.EstimatedPerUnitWithParenthesis
  ].includes(format);
}

export function getNutritionLabelByFormatRaw(format: NutritionDisplayFormat, unit: string|null, amountPerUnit: number|null, isGram:boolean, noEstimation: boolean = false):string {
  const minUnit = isGram ? 'g' : 'ml';

  const append = (!noEstimation && doesNutritionLabelByFormatHasEstimation(format) ? '※推定値' : '');

  switch (format) {
    case NutritionDisplayFormat.PerGram:
    case NutritionDisplayFormat.EstimatedPerGram:
      // TODO
      return " " + i18n.t('spec.common.per', { n: amountPerUnit + minUnit }) + " " + append;
    case NutritionDisplayFormat.PerGramWithParenthesis:
    case NutritionDisplayFormat.EstimatedPerGramWithParenthesis:
      return "（" + i18n.t('spec.common.per', { n: amountPerUnit + minUnit }) + "）" + append;
    case NutritionDisplayFormat.PerGramWithUnit :
    case NutritionDisplayFormat.EstimatedPerGramWithUnit :
      return " " + i18n.t('spec.common.per', {n: `${unit}（${amountPerUnit + minUnit}）`}) + " " + append;
    case NutritionDisplayFormat.PerUnit:
    case NutritionDisplayFormat.EstimatedPerUnit:
      return " " + i18n.t('spec.common.per', { n: unit }) + " " + append;
    case NutritionDisplayFormat.PerUnitWithParenthesis:
    case NutritionDisplayFormat.EstimatedPerUnitWithParenthesis:
      return "（" + i18n.t('spec.common.per', { n: unit }) + "）" + append;
    default:
      return i18n.t('spec.common.per', { n: 'X' + minUnit }) + append;
  }
}
export function getNutritionLabelByFormat(label: ILabelNutrition, noEstimation: boolean = false):string {
  return getNutritionLabelByFormatRaw(label.nutritionDisplayFormat, label.nutritionUnit, label.nutritionAmountPerUnit, label.nutritionIsGram, noEstimation);
}
