import {
  requiredRule,
  createMaxRule, createMinRule, createDecimalRule, createMaxStringRule, typeNumberOrEmptyRule
} from '@/utils/validation-rules';

import IngredientNutritionEntity from "@/entities/ingredient-nutrition-entity";
import {NutritionValue} from "@/entities/nutrition-value";
import {i18n} from "@/bootstraps/locale";
import ILabelNutrition, {
  getNutritionLabelByFormat,
  NutritionDisplayFormat
} from "@/entities/interfaces/i-label-nutrition";

export default class SpecNutritionEntity extends IngredientNutritionEntity implements ILabelNutrition {
  public specId!:number;
  public displayFormat!: NutritionDisplayFormat;
  public unit!:string|null;
  public amountPerUnit!:number;
  public isGram!:boolean;
  public gramPerMilliliter!:number;
  public note!:string|null;

  constructor(init:Partial<SpecNutritionEntity> = {}) {
    super(init);
    this.displayFormat = init.displayFormat || NutritionDisplayFormat.PerGram;
    this.unit = init.unit || null;
    this.amountPerUnit = init.amountPerUnit || 100;
    this.isDetail = !!init.isDetail;
    this.isGram = init.isGram === undefined ? true : init.isGram;
    this.gramPerMilliliter = init.gramPerMilliliter || 1;
    this.note = init.note || null;
  }

  protected get nonNutritionKeys() {
    return ['id', 'specId', 'displayFormat', 'unit', 'amountPerUnit', 'referredNutritionId', 'isGram', 'gramPerMilliliter', 'isDetail', 'note'];
  }
  protected getNutritionKeys(obj) {
    return super.getNutritionKeys(obj).filter(k => !this.nonNutritionKeys.concat(super.nonNutritionKeys).includes(k));
  }
  public get nutritionKeys() {
    return this.getNutritionKeys(this);
  }

  public getNutritionPerAmount(key:string): NutritionValue {
    const valuePer100g = this[key] as NutritionValue;
    return getNutritionPerAmount(valuePer100g, this.amountPerUnit, this.isGram, this.gramPerMilliliter, key);
  }

  public get nutritionDisplayFormat(): NutritionDisplayFormat { return this.displayFormat };
  public set nutritionDisplayFormat(val:NutritionDisplayFormat) { this.displayFormat = val; };
  public get nutritionAmountPerUnit(): number { return this.amountPerUnit };
  public set nutritionAmountPerUnit(val:number) { this.amountPerUnit = val; };
  public get nutritionGramPerMilliliter(): number { return this.gramPerMilliliter };
  public set nutritionGramPerMilliliter(val:number) { this.gramPerMilliliter = val; };
  public get nutritionIsGram(): boolean { return this.isGram };
  public set nutritionIsGram(val:boolean) { this.isGram = val; };
  public get nutritionUnit(): string|null { return this.unit };
  public set nutritionUnit(val:string|null) { this.unit = val; };

  public getNutritionLabelFormat(): string {
    return getNutritionLabelByFormat(this);
  }
}

export function getNutritionPerAmount(
  valuePer100g:NutritionValue, amountPerUnit:number, isGram:boolean, gramPerMilliliter:number, property: string
): NutritionValue {
  return NutritionValue.calc([valuePer100g], () => {
    if (valuePer100g.isNoneOrBlank) return null;
    const valuePer1Gram = (valuePer100g.number || 0) / 100;
    const amountGram = isGram ? amountPerUnit :  (amountPerUnit * gramPerMilliliter);
    return amountGram * valuePer1Gram;
  }, property, amountPerUnit);
}

export const ValidatorRules = {
  unit: [createMaxStringRule()],
  note: [createMaxRule(512, 'char')],
  amountPerUnit: [requiredRule, createMinRule(0, 'number'), createDecimalRule(9, 3)],
  gramPerMilliliter: [requiredRule, createMinRule(0, 'number'), createDecimalRule(11, 6)],
};
