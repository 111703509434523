import {getTextByLang as t} from "@/bootstraps/locale";

export const BrandTypesLang = {
  'ナショナルブランド': t({
    ja: "ナショナルブランド（NB）",
    en: "National Brand (NB)",
    cn: "国际品牌"
  }),
  'プライベートブランド': t({
    ja: "プライベートブランド（PB）",
    en: "Private Brand (PB)",
    cn: "自创品牌"
  }),
  'インストア商品': t({
    ja: "インストア商品",
    en: "In-store Products",
    cn: "店内商品"
  }),
  'アウトパック商品': t({
    ja: "アウトパック商品",
    en: "Pack out Products",
    cn: "外包装商品"
  }),
  '留型': t({
    ja: "留型",
    en: "Limited Edition",
    cn: "限量版"
  }),
  '特注': t({
    ja: "特注",
    en: "Custom Order",
    cn: "定制订单"
  }),
};
