import RepositoryBase from "@/repositories/repository-base";
import ObjectUtils from "@/utils/object-utils";

export class JicfsCategory {
  public id!:number;
  public name!:string;
  constructor(init:Partial<JicfsCategory> = {}) {
    ObjectUtils.assignLiteralFields(this, init);
  }
}

export default class JicfsCategoryRepository extends RepositoryBase<JicfsCategory> {
  protected endpoint = "jicfs-categories";
  protected ctor:new(data) => JicfsCategory = JicfsCategory;

  public async list() {
    const res = await this.get(this.endpoint + '/list');
    return res.data;
  }
}
