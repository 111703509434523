import {getTextByLang as t} from "@/bootstraps/locale";

export const AllerngensLang = {
  'えび': t({
    ja: "えび",
    en: "Shrimp",
    cn: "小虾"
  }),
  'かに': t({
    ja: "かに",
    en: "Crab",
    cn: "螃蟹"
  }),
  '小麦': t({
    ja: "小麦",
    en: "Wheat",
    cn: "小麦"
  }),
  'そば': t({
    ja: "そば",
    en: "Soba",
    cn: "荞麦"
  }),
  '卵': t({
    ja: "卵",
    en: "Egg",
    cn: "鸡蛋"
  }),
  '乳': t({
    ja: "乳",
    en: "Milk",
    cn: "牛奶"
  }),
  '落花生': t({
    ja: "落花生",
    en: "Peanut",
    cn: "花生"
  }),
  'アーモンド': t({
    ja: "アーモンド",
    en: "Almond",
    cn: "杏仁"
  }),
  'あわび': t({
    ja: "あわび",
    en: "Ear shell",
    cn: "耳壳"
  }),
  'いか': t({
    ja: "いか",
    en: "Squid",
    cn: "墨鱼"
  }),
  'いくら': t({
    ja: "いくら",
    en: "Salmon roe",
    cn: "鲑鱼子"
  }),
  'オレンジ': t({
    ja: "オレンジ",
    en: "Orange",
    cn: "橙子"
  }),
  'カシューナッツ': t({
    ja: "カシューナッツ",
    en: "Cashew nuts",
    cn: "腰果"
  }),
  'キウイフルーツ': t({
    ja: "キウイフルーツ",
    en: "Kiwi fruit",
    cn: "猕猴桃"
  }),
  '牛肉': t({
    ja: "牛肉",
    en: "Beef",
    cn: "牛肉"
  }),
  'くるみ': t({
    ja: "くるみ",
    en: "Walnut",
    cn: "胡桃木"
  }),
  'ごま': t({
    ja: "ごま",
    en: "Sesame",
    cn: "芝麻"
  }),
  'さけ': t({
    ja: "さけ",
    en: "Chum salmon",
    cn: "鲑鱼"
  }),
  'さば': t({
    ja: "さば",
    en: "Mackerel",
    cn: "鲭鱼"
  }),
  '大豆': t({
    ja: "大豆",
    en: "Soy bean",
    cn: "大豆"
  }),
  '鶏肉': t({
    ja: "鶏肉",
    en: "Chicken",
    cn: "鸡肉"
  }),
  'バナナ': t({
    ja: "バナナ",
    en: "Banana",
    cn: "香蕉"
  }),
  '豚肉': t({
    ja: "豚肉",
    en: "Pork",
    cn: "猪肉"
  }),
  'まつたけ': t({
    ja: "まつたけ",
    en: "Matsutake mushroom",
    cn: "松口蘑"
  }),
  'マカダミアナッツ': t({
    ja: "マカダミアナッツ",
    en: "Macadamia nuts",
    cn: "夏威夷果"
  }),
  'もも': t({
    ja: "もも",
    en: "Peach",
    cn: "桃子"
  }),
  'やまいも': t({
    ja: "やまいも",
    en: "Japanese yam",
    cn: "日本山药"
  }),
  'りんご': t({
    ja: "りんご",
    en: "Apple",
    cn: "苹果"
  }),
  'ゼラチン': t({
    ja: "ゼラチン",
    en: "Gelatine",
    cn: "明胶"
  }),
  'たん白加水分解物': t({
    ja: "たん白加水分解物（魚介類）",
    en: "Protein hydrolysate (seafood)",
    cn: "蛋白质水解物(海鲜类)"
  }),
  '魚醤': t({
    ja: "魚醤（魚介類）",
    en: "Fish sauce (seafood)",
    cn: "鱼酱（海鲜类）"
  }),
  '魚醤パウダー': t({
    ja: "魚醤パウダー（魚介類）",
    en: "Fish sauce powder (seafood)",
    cn: "鱼酱粉（海鲜类）"
  }),
  '魚肉すり身': t({
    ja: "魚肉すり身（魚介類）",
    en: "Minced Fish (seafood)",
    cn: "鱼糜（海鲜类）"
  }),
  '魚油': t({
    ja: "魚油（魚介類）",
    en: "Fish oil (seafood)",
    cn: "鱼油（海鲜类）"
  }),
  '魚介エキス': t({
    ja: "魚介エキス（魚介類）",
    en: "Seafood extract (seafood)",
    cn: "海鲜提取物（海鲜类）"
  }),
  '米': t({
    ja: "米",
    en: "Rice",
    cn: "米"
  }),
};
