import ObjectUtils from "@/utils/object-utils";
import {createRangeRule, requiredOnChangeRule, requiredRule} from "@/utils/validation-rules";
import RowKey from "@/entities/concerns/rowkey";

export class GmoEntity {
  public cropType:number|null = null;
  public gmoType:number|null = null;

  constructor(init:Partial<IngredientItemGmoEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);
  }

  public get cropTypeLabel() {
    if (!this.cropType) return '';
    const type = CropTypes.find(i => i.key === this.cropType);
    if (!type) throw new Error('invalid crop type');
    return type.label;
  }

  public getGmoType(): GmoType | null {
    if (!this.gmoType) return null;
    const type = GmoTypes.find(i => i.key === this.gmoType);
    if (!type) throw new Error('invalid crop type');
    return type;
  }
  public getGmoTypeLabel(companySetting:CompanySettingEntity) {
    const type = this.getGmoType();
    if (!type) return '';
    if (this.gmoType === GmoTypesEnum.NonGmoDeprecated) {
      return companySetting.displayDeprecatedNonGmoAsNonGmo
        ? GmoTypes.find(i => i.key === GmoTypesEnum.NonGmo)!.label
        : GmoTypes.find(i => i.key === GmoTypesEnum.IpHandling)!.label;
    }
    return type.label;
  }
  public getLabel(companySetting:CompanySettingEntity):string {
    const name = this.cropTypeLabel || '';
    return this.getGmoTypeLabel(companySetting) ? `${name}（${this.getGmoTypeLabel(companySetting)}）` : name;
  }
  public getLabelForShow():string {
    const name = this.cropTypeLabel || '';
    return this.getGmoType() ? `${name}（${this.getGmoType()!.label}）` : name;
  }

  public get isEmpty():boolean {
    return !this.cropType && !this.gmoType;
  }
  public get invalid():boolean {
    return (!!this.cropType && !this.gmoType) || (!this.cropType && !!this.gmoType);
  }
}

export default class IngredientItemGmoEntity extends GmoEntity {
  public id!:number;
  public ingredientItemId:number|null = null;
  public rowKey!:number;

  constructor(init:Partial<IngredientItemGmoEntity> = {}) {
    super(init);
    ObjectUtils.assignLiteralFields(this, init);
    this.rowKey = RowKey.getRowKey();
  }
}

import {GmoCropsLang as langC, GmoTypesLang as langT} from "@/lang/enum/gmos-lang";
import {i18n} from "@/bootstraps/locale";
import {CompanySettingEntity} from "@/entities/company-setting-entity";
import VueI18n from "vue-i18n";
import TranslateResult = VueI18n.TranslateResult;
import appType, {AppTypes} from "@/app-types";
import GmoTypeSelect from "@/views/label/companies/ingredient/components/ingredient-item-table/GmoTypeSelect.vue";

export enum GmoCropTypesEnum {
  Soy = 1,
  Corn = 2,
  Potato = 3,
}
export const CropTypes = [
  {key: GmoCropTypesEnum.Soy, label: langC.大豆},
  {key: GmoCropTypesEnum.Corn, label: langC.とうもろこし},
  {key: GmoCropTypesEnum.Potato, label: langC.ばれいしょ},
  {key: 4, label: langC.なたね},
  {key: 5, label: langC.綿実}, // めんじつ
  {key: 6, label: langC.アルファルファ},
  {key: 7, label: langC.てん菜},
  {key: 8, label: langC.パパイヤ},
  {key: 9, label: langC.からしな},
];

export enum GmoTypesEnum {
  IsGmo = 1,
  NonGmo = 2,
  NotIpHandled = 3,
  // and more
  HighLysine =  6, // cornのみ
  HighLysineMixed =  7, // cornのみ
  StearidoneAcid =  8, // soyのみ
  StearidoneAcidMixed =  9, // soyのみ
  IpHandling= 11,
  NonGmoDeprecated = 50,
}
export type GmoType = {key: number; option: string; label: string|null; group: TranslateResult};
export const GmoTypes: GmoType[] = [
  {key: GmoTypesEnum.IsGmo, option: langT.遺伝子組換え, label: langT.遺伝子組換え, group: i18n.t("基本")},
  {key: GmoTypesEnum.IpHandling, option: langT['分別生産流通管理済み（混入率：5％以下）'], label: langT.分別生産流通管理済み, group: i18n.t("基本")},
  {key: GmoTypesEnum.NonGmo, option: langT['遺伝子組換えでない（混入率：0％）'], label:  langT.遺伝子組換えでない, group: i18n.t("基本")},
  {
    key: GmoTypesEnum.NonGmoDeprecated,
    option: appType === AppTypes.Spec ?
      langT['遺伝子組換えでない（2023年度改正前基準）'] :
      langT['（廃止予定）遺伝子組換えでない（2023年度改正前基準）'],
    label: appType === AppTypes.Spec ?
      langT['遺伝子組換えでない（2023年度改正前基準）'] :
      langT['（廃止予定）遺伝子組換えでない（2023年度改正前基準）'],
    group: i18n.t("基本")
  },
  {key: GmoTypesEnum.NotIpHandled, option: langT.遺伝子組換え不分別, label:  langT.遺伝子組換え不分別, group: i18n.t("基本")},
  {key: GmoTypesEnum.HighLysine, option: langT.高リシン遺伝子組換え, label: langT.高リシン遺伝子組換え, group: i18n.t("特定遺伝子組換え作物")},
  {key: GmoTypesEnum.HighLysineMixed, option: langT.高リシン遺伝子組換えのものを混合, label: langT.高リシン遺伝子組換えのものを混合, group: i18n.t("特定遺伝子組換え作物")},
  {key: GmoTypesEnum.StearidoneAcid, option: langT.ステアリドン酸産生遺伝子組換え, label: langT.ステアリドン酸産生遺伝子組換え, group: i18n.t("特定遺伝子組換え作物")},
  {key: GmoTypesEnum.StearidoneAcidMixed, option: langT.ステアリドン酸産生遺伝子組換えのものを混合, label: langT.ステアリドン酸産生遺伝子組換えのものを混合, group: i18n.t("特定遺伝子組換え作物")},
  {key: 10, option: langT.遺伝子組換えのものを分別, label:langT.遺伝子組換えのものを分別, group: i18n.t("基本")},
];
export function getGmoTypesForSelection(cropType: GmoCropTypesEnum) {
  return GmoTypes.filter(gmo => {
    return canSelectGmoType(gmo.key, cropType);
  }).map(gmo => {
    if (![GmoCropTypesEnum.Soy, GmoCropTypesEnum.Corn].includes(cropType) && [GmoTypesEnum.IpHandling, GmoTypesEnum.NonGmo].includes(gmo.key)) {
      return { ...gmo, option: gmo.label };
    }
    return gmo;
  });
}
export function canSelectGmoType(gmoType: GmoTypesEnum, cropType:GmoCropTypesEnum) {
  if ([GmoTypesEnum.StearidoneAcid, GmoTypesEnum.StearidoneAcidMixed].includes(gmoType) && cropType !== GmoCropTypesEnum.Soy) {
    return false;
  }
  if ([GmoTypesEnum.HighLysine, GmoTypesEnum.HighLysineMixed].includes(gmoType) && cropType !== GmoCropTypesEnum.Corn) {
    return false;
  }
  if ([GmoTypesEnum.IpHandling, GmoTypesEnum.NonGmoDeprecated].includes(gmoType) && ![GmoCropTypesEnum.Soy, GmoCropTypesEnum.Corn].includes(cropType)) {
    return false;
  }
  return true;
}

export const ValidatorRules:any = {
  ingredientItemId: [requiredRule],
  cropType: [requiredOnChangeRule],
  gmoType: [requiredOnChangeRule],
};
