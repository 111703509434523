import {
  createMaxRule,
  createMinRule,
  createDecimalRule,
  createMaxStringRule,
  getTypeIntRule
} from '@/utils/validation-rules';
import RowKey from "@/entities/concerns/rowkey";

import ObjectUtils from "@/utils/object-utils";
import isEqual from 'lodash/isEqual';
import {i18n} from "@/bootstraps/locale";

export default class SpecPackingFormEntity {
  public rowKey!:number;

  public id!:number;
  public type!:PackingUnitTypeEnum;

  public width:number|null = null; //mm
  public height:number|null = null; //mm
  public depth:number|null = null; //mm
  public weight:number|null = null; // gram
  public milliliter:number|null = null; // ml
  public totalWeight:number|null = null; // gram

  public quantity:number|null = null;
  public janCode:string|null = null;
  public itfCode:string|null = null;

  public unit:string|null = null;
  public active:boolean = true;

  public get typeLabel(): string {
    return PackingUnitTypes[this.type];
  }

  public get isPiece(): boolean {
    return this.type === PackingUnitTypeEnum.Piece;
  }

  constructor(init:Partial<SpecPackingFormEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);

    this.rowKey = RowKey.getRowKey();
  }
}

export const ValidatorRules = {
  width: [createMinRule(0, 'number'), createDecimalRule(11,3)],
  height: [createMinRule(0, 'number'), createDecimalRule(11,3)],
  depth: [createMinRule(0, 'number'), createDecimalRule(11,3)],
  weight: [createMinRule(0, 'number'), createDecimalRule(11,3)],
  milliliter: [createMinRule(0, 'number'), createDecimalRule(11,3)],
  totalWeight: [createMinRule(0, 'number'), createDecimalRule(11,3)],
  quantity: [getTypeIntRule()],
  janCode: [createMaxRule(13, 'char')],
  itfCode: [createMaxRule(14, 'char')],
  unit: [createMaxStringRule()],
};

export enum PackingUnitTypeEnum {
  Piece = 1,
  Pack = 2,
  Ball = 3,
  Case = 4,
  Kou = 5,
}

export const PackingUnitTypes = {
  [PackingUnitTypeEnum.Piece]: i18n.t('ピース'),
  [PackingUnitTypeEnum.Pack]:  i18n.t('パック'),
  [PackingUnitTypeEnum.Ball]:  i18n.t('ボール'),
  [PackingUnitTypeEnum.Case]:  i18n.t('ケース'),
  [PackingUnitTypeEnum.Kou]:  i18n.t('甲')
} as {[key:number]:string};
