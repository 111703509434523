import ObjectUtils from "@/utils/object-utils";
import {AllerngensLang as lang} from "@/lang/enum/allerngens-lang";

export interface IAllergen {
  id:number;
  order: number;
  name:string;
  required:boolean;
  readonly excludeOnLabelDisplay:boolean;
}

// TODO: アレルゲンマスタをなくす（Enumのみにする）
export default class AllergyEntity implements IAllergen {
  public id!:number;
  public order!: number;
  public name!:string;
  public required!:boolean;

  public readonly excludeOnLabelDisplay:boolean;

  constructor(init:Partial<AllergyEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);

    this.excludeOnLabelDisplay = AllergenList.filter(a => a.excludeOnLabelDisplay).map(a => a.id).includes(this.id);
  }

  public getDisplayName(isForAdditive:boolean = false): string {
    return getAllergenDisplayName(this.name, isForAdditive);
  }
}

// 特定原材料が乳の場合は「乳成分を含む」と表記する。
export function getAllergenDisplayName(name: string, isForAdditive:boolean = false) {
  if (isForAdditive) {
    return name;
  }
  if (name === '乳') {
    return '乳成分';
  }
  return name;
}
export function getAllergenNameById(id:number) {
  const allergen = AllergenList.find(a => a.id == id);
  return allergen ? allergen.name : '';
}
export function getAllergensByIds(ids:number[]): AllergyEntity[] {
  return ids.map(id => {
    const allergen = AllergenList.find(a => a.id == id);
    if (!allergen) throw new Error(`Allergen not found: ${id}`);
    return new AllergyEntity(allergen);
  });
}

export const AllergenList:IAllergen[] = [
  {id: 1, order: 1, name: lang.えび, required: true, excludeOnLabelDisplay: false},
  {id: 2, order: 2, name: lang.かに, required: true, excludeOnLabelDisplay: false},
  {id: 16, order: 3, name: lang.くるみ, required: true, excludeOnLabelDisplay: false},
  {id: 3, order: 4, name: lang.小麦, required: true, excludeOnLabelDisplay: false},
  {id: 4, order: 5, name: lang.そば, required: true, excludeOnLabelDisplay: false},
  {id: 5, order: 6, name: lang.卵, required: true, excludeOnLabelDisplay: false},
  {id: 6, order: 7, name: lang.乳, required: true, excludeOnLabelDisplay: false},
  {id: 7, order: 8, name: lang.落花生, required: true, excludeOnLabelDisplay: false},

  {id: 8, order: 9, name: lang.アーモンド, required: false, excludeOnLabelDisplay: false},
  {id: 9, order: 10, name: lang.あわび, required: false, excludeOnLabelDisplay: false},
  {id: 10, order: 11, name: lang.いか, required: false, excludeOnLabelDisplay: false},
  {id: 11, order: 12, name: lang.いくら, required: false, excludeOnLabelDisplay: false},
  {id: 12, order: 13, name: lang.オレンジ, required: false, excludeOnLabelDisplay: false},
  {id: 13, order: 14, name: lang.カシューナッツ, required: false, excludeOnLabelDisplay: false},
  {id: 14, order: 15, name: lang.キウイフルーツ, required: false, excludeOnLabelDisplay: false},
  {id: 15, order: 16, name: lang.牛肉, required: false, excludeOnLabelDisplay: false},
  {id: 17, order: 17, name: lang.ごま, required: false, excludeOnLabelDisplay: false},
  {id: 18, order: 18, name: lang.さけ, required: false, excludeOnLabelDisplay: false},
  {id: 19, order: 19, name: lang.さば, required: false, excludeOnLabelDisplay: false},
  {id: 20, order: 20, name: lang.大豆, required: false, excludeOnLabelDisplay: false},
  {id: 21, order: 21, name: lang.鶏肉, required: false, excludeOnLabelDisplay: false},
  {id: 22, order: 22, name: lang.バナナ, required: false, excludeOnLabelDisplay: false},
  {id: 23, order: 23, name: lang.豚肉, required: false, excludeOnLabelDisplay: false},
  {id: 25, order: 25, name: lang.もも, required: false, excludeOnLabelDisplay: false},
  {id: 26, order: 26, name: lang.やまいも, required: false, excludeOnLabelDisplay: false},
  {id: 27, order: 27, name: lang.りんご, required: false, excludeOnLabelDisplay: false},
  {id: 28, order: 28, name: lang.ゼラチン, required: false, excludeOnLabelDisplay: false},
  {id: 29, order: 29, name: lang.マカダミアナッツ, required: false, excludeOnLabelDisplay: false},

  {id: 51, order: 51, name: lang.たん白加水分解物, required: false, excludeOnLabelDisplay: false},
  {id: 52, order: 52, name: lang.魚醤, required: false, excludeOnLabelDisplay: false},
  {id: 53, order: 53, name: lang.魚醤パウダー, required: false, excludeOnLabelDisplay: false},
  {id: 54, order: 54, name: lang.魚肉すり身, required: false, excludeOnLabelDisplay: false},
  {id: 55, order: 55, name: lang.魚油, required: false, excludeOnLabelDisplay: false},
  {id: 56, order: 56, name: lang.魚介エキス, required: false, excludeOnLabelDisplay: false},

  {id: 24, order: 24, name: lang.まつたけ, required: false, excludeOnLabelDisplay: true},
  {id: 101, order: 101, name: lang.米, required: false, excludeOnLabelDisplay: true},
];
